import { isEmpty } from 'lodash';
import getDataFromLocalStorage from '../../../utilities/get-data-from-local-storage';
import { PIPELINE_AUTOSAVE_STORAGE_KEY } from './constants';

const getPipelineStorageData = (pipelineId) => {
  const { targetObject } = getDataFromLocalStorage({
    storageKey: PIPELINE_AUTOSAVE_STORAGE_KEY,
    targetKey: pipelineId,
  });
  const isDataExists = targetObject && !isEmpty(targetObject);
  const pipelineStorageData = isDataExists && JSON.parse(targetObject);
  return {
    isDataExists,
    pipelineStorageData,
  };
};

export default getPipelineStorageData;

import Card from '@shoreag/base/Card';
import Dropdown from '@shoreag/base/Dropdown';
import Input from '@shoreag/base/Input';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import TextArea from '@shoreag/base/TextArea';
import WhenFieldChanges from '@shoreag/base/WhenFieldChanges';
import get from 'lodash/get';
import has from 'lodash/has';
import { Field } from 'react-final-form';
import { composeValidations, required } from '@shoreag/validations';
import { paramCase } from 'change-case';
import { useQuery } from 'react-apollo';
import { AuthContext } from '@shoreag/auth';
import PipelineDropdown from './PipelineDropdown';
import config from '../../config.json';
import partnerQuery from '../../graphql/queries/all-partner.gql';
import {
  PIPELINE_INPUT_TYPES,
  YES_NO_OPTIONS,
} from '../../utilities/constants';
import {
  endWithLetter,
  lettersNumbersDashesAndUnderscores,
  startWithLetter,
} from '../../utilities/validations';
import tradingPartners from '../../utilities/get-trading-partners';

const PipelineDetailsFormSection = ({ disabled, formContext }) => {
  const { user } = useContext(AuthContext);
  const { data } = useQuery(partnerQuery);
  const partnerList = tradingPartners(get(data, 'allPartner', []), user);

  return (
    <Card maxWidth="maxWidths.form" mx="auto">
      <WhenFieldChanges
        becomes={has(formContext, 'values.displayName')}
        field="displayName"
        set="pipelineCode"
        to={paramCase(get(formContext, 'values.displayName', ''))}
      />
      <Field
        component={Input}
        disabled={disabled}
        label="Display Name *"
        name="displayName"
        validate={composeValidations(required, startWithLetter, endWithLetter)}
      />
      <Field
        component={Input}
        disabled={disabled}
        label={`${config.nomenclature.Pipeline} Code *`}
        name="pipelineCode"
        validate={composeValidations(
          required,
          lettersNumbersDashesAndUnderscores,
          startWithLetter,
          endWithLetter
        )}
      />
      <Field
        component={TextArea}
        disabled={disabled}
        label="Description *"
        name="description"
        validate={required}
      />
      <Field
        component={Dropdown}
        label="Partner Name"
        name="partner"
        options={[{ id: '', partnerName: 'Select' }, ...partnerList].map(
          (partner) => ({
            label: partner.partnerName,
            value: partner.id,
          })
        )}
      />
      <Field
        component={Dropdown}
        label="Auto Trigger"
        name="autoTrigger"
        onChange={() =>
          formContext.form.change('inputs', [
            { name: 'dataset', type: PIPELINE_INPUT_TYPES.DATASET },
          ])
        }
        options={YES_NO_OPTIONS}
      />
      <PipelineDropdown
        formContext={formContext}
        isDisabled={disabled}
        isMandatory={false}
      />
    </Card>
  );
};

PipelineDetailsFormSection.propTypes = {
  disabled: PropTypes.bool,
  formContext: PropTypes.shape({
    form: PropTypes.shape({ change: PropTypes.func }),
    values: PropTypes.shape({ displayName: PropTypes.string }),
  }),
};

PipelineDetailsFormSection.defaultProps = {
  disabled: false,
  formContext: null,
};

export default PipelineDetailsFormSection;

import Box from '@shoreag/base/Box';
import Input from '@shoreag/base/Input';
import React from 'react';
import { Field } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import { composeValidations, email, required } from '@shoreag/validations';
import PropTypes from 'prop-types';
import AddButton from '../AddButton';
import RemoveButton from '../RemoveButton';

const EmailFormSection = ({ formContext, formName, ...rest }) => (
  <Box {...rest}>
    <FieldArray name={formName}>
      {({ fields }) => (
        <>
          {fields.map((name, index) => (
            <Box key={name} sx={{ display: 'flex', mt: 6 }}>
              <Field
                component={Input}
                data-cy={`${name}.emailAddress`}
                label="Email Address"
                name={`${name}.emailAddress`}
                validate={composeValidations(required, email)}
              />
              <RemoveButton onClick={() => fields.remove(index)} sx={{ mt: 5 }}>
                Remove
              </RemoveButton>
            </Box>
          ))}
          <AddButton
            data-cy="addEmail"
            label="Add Email"
            labelSx={{ fontSize: 2 }}
            onClick={() => fields.push({})}
            sx={{ fontSize: 3, height: '1.5rem', width: '1.5rem' }}
            wrapperSx={{ mt: 5 }}
          />
        </>
      )}
    </FieldArray>
  </Box>
);

EmailFormSection.propTypes = {
  formContext: PropTypes.shape({
    invalid: PropTypes.bool,
    values: PropTypes.shape({
      escalationLevel: PropTypes.arrayOf(
        PropTypes.shape({
          severity: PropTypes.string,
        })
      ),
    }),
  }),
  formName: PropTypes.string,
};

EmailFormSection.defaultProps = {
  formContext: {},
  formName: 'emails',
};

export default EmailFormSection;

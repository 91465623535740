import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Query } from 'react-apollo';
import { get } from 'lodash';
import { navigate } from 'gatsby';
import Box from '@shoreag/base/Box';
import Modal from '@shoreag/base/Modal';
import ReactGA from 'react-ga';
import stripTypename from '@shoreag/helpers/strip-typename';
import ToolbarButton from '../ToolbarButton';
import config from '../../config.json';
import valueMapperDefinitionQuery from '../../graphql/queries/all-value-mapper-definitions.gql';
import parseUuid from '../../utilities/parse-uuid';
import { PIPELINE_INPUT_TYPES } from '../../utilities/constants';
import generateParsePipelineSteps from '../PipelineEditPage/utilities/generate-parse-pipeline-steps';
import useSaveUpdatePipeline from '../PipelineEditPage/lib/useSaveUpdatePipeline';

const PipelineEditHandler = ({ pipelineData }) => {
  const PIPELINE_ATTR_TOBE_REMOVED = [
    'deactivated',
    'executedBy',
    'executions',
    'executionOffset',
  ];

  const [newPipelineId, setNewPipelineId] = useState(null);
  const [showError, setShowError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const { saveUpdatePipeline, savingError } = useSaveUpdatePipeline();

  const pipelineId = get(pipelineData, 'pipeline.id', '');
  const isPublished = get(pipelineData, 'pipeline.isPublished', false);

  const { allStepDefinitions, pipeline } = pipelineData;
  const modifiedPipeline = {
    ...pipeline,
    steps: pipeline.steps.map((step) => {
      const modifiedStep = { ...step };
      delete modifiedStep.displayName;
      return modifiedStep;
    }),
  };
  PIPELINE_ATTR_TOBE_REMOVED.forEach((i) => delete modifiedPipeline[i]);

  const handleSavePipeline = useCallback(
    async (data) => {
      setIsLoading(true);
      try {
        const pipelineCopiedFromId = get(data, 'pipeline.copiedFrom.id', '');
        const { code: pipelineCode, version: pipelineVersionNo } = parseUuid(
          pipelineCopiedFromId
        );
        const isPublished = get(data, 'pipeline.isPublished', false);

        const pipelineValues = stripTypename({
          ...data.pipeline,
          autoTrigger: data.pipeline.autoTrigger || undefined,
          copyPipelineCode: pipelineCode,
          copyPipelineVersionId: pipelineVersionNo,
          inputs: [
            ...data.pipeline.inputs,
            ...(data.pipeline.inputs.length
              ? []
              : [{ name: 'dataset', type: PIPELINE_INPUT_TYPES.DATASET }]),
          ],
          partner: data.pipeline.partner ? data.pipeline.partner.id : undefined,
          steps: data.pipeline.steps.map(
            generateParsePipelineSteps(
              data.allStepDefinitions,
              data.allValueMapperDefinitions
            )
          ),
        });

        const { newPipelineId } = await saveUpdatePipeline({
          data,
          pipelineId,
          values: {
            ...pipelineValues,
            isNewVersion: isPublished,
            isPublished: false,
          },
        });

        if (newPipelineId) {
          ReactGA.event({
            action: `${config.nomenclature.Pipeline} Edited`,
            category: config.nomenclature.Pipelines,
            label: newPipelineId,
          });
          setNewPipelineId(newPipelineId);
        }
      } catch (error) {
        setShowError(true);
      } finally {
        setIsLoading(false);
      }
    },
    [pipelineId, saveUpdatePipeline]
  );

  useEffect(() => {
    if (savingError) {
      setShowError(true);
    }
  }, [savingError]);

  useEffect(() => {
    if (newPipelineId && newPipelineId !== pipelineId) {
      navigate(`/${config.nomenclature.pipelines}/${newPipelineId}/edit`);
      setIsLoading(false);
    }
  }, [newPipelineId]);

  return (
    <Query query={valueMapperDefinitionQuery} skip={!isPublished}>
      {({ data: allValueMapperDefinitionsData, loading }) => {
        const allValueMapperDefinitions = get(
          allValueMapperDefinitionsData,
          'allValueMapperDefinitions',
          []
        );
        const data = {
          allStepDefinitions,
          allValueMapperDefinitions,
          pipeline: modifiedPipeline,
        };
        const buttonLoading = loading || isLoading;
        return (
          <>
            <ToolbarButton
              disabled={
                !(pipelineData && valueMapperDefinitionQuery) || buttonLoading
              }
              icon="edit"
              label="Edit"
              loading={buttonLoading}
              onClick={() => {
                if (!isPublished) {
                  navigate(
                    `/${config.nomenclature.pipelines}/${pipelineId}/edit`
                  );
                } else {
                  handleSavePipeline(data);
                }
              }}
              type="button"
            />
            {showError && (
              <Modal
                closeText="Close"
                isOpen={showError}
                onClose={() => setShowError(false)}
                title="Error"
              >
                <Box sx={{ color: 'error', textAlign: 'center' }}>
                  Something went wrong! please try again.
                  {savingError ? <p>{savingError}</p> : ''}
                </Box>
              </Modal>
            )}
          </>
        );
      }}
    </Query>
  );
};

PipelineEditHandler.propTypes = {
  pipelineData: PropTypes.shape({
    allStepDefinitions: PropTypes.arrayOf(PropTypes.shape({})),
    pipeline: PropTypes.shape({
      autoTrigger: PropTypes.string,
      inputs: PropTypes.arrayOf(PropTypes.shape({})),
      partner: PropTypes.shape({
        id: PropTypes.string,
      }),
      steps: PropTypes.arrayOf(PropTypes.shape({})),
    }),
  }).isRequired,
};

export default PipelineEditHandler;

import moment from 'moment';

export const endDateModifier = (startDate, scheduleInterval, endDate) => {
  if (scheduleInterval === 'once') {
    return moment(new Date(startDate)).add(1, 'd').format('MM/DD/YYYY');
  }
  return moment(endDate).format('MM/DD/YYYY');
};

export const startDateModifier = (startDate) => {
  return moment(startDate).format('MM/DD/YYYY');
};

export const minEndDateSetter = (startDate, scheduleInterval) => {
  switch (scheduleInterval) {
    case '@daily':
      return moment(new Date(startDate)).add(1, 'd').toDate();
    case '@weekly':
      return moment(new Date(startDate)).add(1, 'w').toDate();
    case '@monthly':
      return moment(new Date(startDate)).add(1, 'M').toDate();
    case '@yearly':
      return moment(new Date(startDate)).add(1, 'y').toDate();
    default:
      return null;
  }
};

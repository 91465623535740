import Dropdown from '@shoreag/base/Dropdown';
import React from 'react';
import { ApolloConsumer } from 'react-apollo';
import { Field } from 'react-final-form';
import { required } from '@shoreag/validations';
import allDatasetOptimizedQuery from '../../graphql/queries/all-dataset-optimized.gql';

const DatasetSelectFormSection = (props) => (
  <ApolloConsumer>
    {(client) => (
      <Field
        component={Dropdown}
        loadOptions={async (query) => {
          const {
            data: { allDataset },
          } = await client.query({
            fetchPolicy: 'network-only',
            query: allDatasetOptimizedQuery,
            variables: { datasetType: 'user-uploaded', name: query },
          });

          return allDataset.map(({ id, name }) => ({
            label: name,
            value: id,
          }));
        }}
        validate={required}
        {...props}
      />
    )}
  </ApolloConsumer>
);

export default DatasetSelectFormSection;

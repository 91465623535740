import { useCallback, useState } from 'react';
import { useMutation } from 'react-apollo';
import { get } from 'lodash';
import updatePipelineMutation from '../../../../graphql/mutations/update-pipeline.gql';
import formatCaseRules from '../../utilities/format-case-rules';
import formatPipelineSteps from '../../utilities/format-pipeline-steps';

const useSaveUpdatePipeline = () => {
  const [saveSuccess, setSaveSuccess] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [savingError, setSavingError] = useState(null);
  const [newPipelineId, setNewPipelineId] = useState(null);

  const [updatePipeline] = useMutation(updatePipelineMutation);

  const saveUpdatePipeline = useCallback(
    async ({ values, data, pipelineId }) => {
      setIsSaving(true);
      setSaveSuccess(false);
      setSavingError(false);
      const steps = values.steps
        .map(formatCaseRules(data.allStepDefinitions))
        .map(formatPipelineSteps);
      try {
        const {
          data: {
            updatePipeline: { id: newPipelineId, lastUpdated },
          },
        } = await updatePipeline({
          optimisticResponse: {
            __typename: 'Mutation',
            updatePipeline: {
              ...data.pipeline,
              __typename: 'Pipeline',
              autoTrigger: values.autoTrigger,
              inputs: values.inputs.map((input) => ({
                ...input,
                __typename: 'PipelineInput',
              })),
              isPublished: values.isPublished,
              partner: values.partner,
              steps: steps.map((step) => ({
                ...step,
                __typename: 'PipelineStep',
              })),
              tags: values.tags.map((tag) => ({
                ...tag,
                __typename: 'Tag',
              })),
            },
          },
          variables: {
            autoTrigger: values.autoTrigger,
            id: pipelineId,
            inputs: values.inputs,
            isNewVersion: values.isNewVersion,
            isPublished: values.isPublished,
            partner: values.partner,
            steps,
            tags: values.tags,
          },
        });
        if (newPipelineId && lastUpdated) {
          setSaveSuccess(true);
          setNewPipelineId(newPipelineId);
          return { lastUpdated, newPipelineId };
        }
      } catch (e) {
        const error = get(e, 'graphQLErrors[0].message');
        setSavingError(error);
        return { error };
      } finally {
        setIsSaving(false);
      }
    },
    []
  );

  return {
    isSaving,
    newPipelineId,
    saveSuccess,
    saveUpdatePipeline,
    savingError,
  };
};

export default useSaveUpdatePipeline;

import getDataFromLocalStorage from './get-data-from-local-storage';

const setDataInLocalStorage = ({ storageKey, targetKey, value }) => {
  const { storageObject } = getDataFromLocalStorage({
    storageKey,
    targetKey,
  });
  localStorage.setItem(
    storageKey,
    JSON.stringify({
      ...storageObject,
      [targetKey]: value,
    })
  );
};

export default setDataInLocalStorage;

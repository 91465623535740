import Card from '@shoreag/base/Card';
import PropTypes from 'prop-types';
import React, { useContext, useState } from 'react';
import get from 'lodash/get';
import { AuthContext } from '@shoreag/auth';
import { Query } from 'react-apollo';
import ButtonGroups from '../ButtonGroups';
import DashboardFilterForm from '../DashboardFilterForm';
import DashboardPaginator from '../DashboardPaginator';
import Route from '../Route';
import TagTooltipBox from '../TagTooltip/TagTooltipBox';
import ToolbarButton from '../ToolbarButton';
import allPipelineQuery from '../../graphql/queries/all-pipeline.gql';
import config from '../../config.json';
import dashboardConstants from '../../utilities/dashboard-constants';
import isPermitted from '../../utilities/is-permitted';
import setDashboardURL from '../../utilities/set-dashboard-url';
import {
  PERMISSION_ACTIONS,
  PERMISSION_RESOURCES,
} from '../../utilities/constants';
import PipelineTable from './PipelineTable';
import pipelineStatus from '../../graphql/queries/pipeline-status.gql';

const PipelineDashboardPageDummy = ({ location, navigate }) => {
  const { user } = useContext(AuthContext);
  const [showFilters, setShowFilters] = useState(false);

  const {
    filters,
    sortCriteria,
    pageCriteria,
  } = dashboardConstants.getCriterias({
    search: location.search,
    type: 'pipeline',
  });

  const query = dashboardConstants.getQuery({
    filters,
    page: pageCriteria,
    sort: sortCriteria,
    user,
  });

  const hasWritePermission = isPermitted({
    desiredAction: PERMISSION_ACTIONS.W,
    desiredResource: PERMISSION_RESOURCES.PIPELINES,
    ...user,
  });

  // const hasExecutePermission = isPermitted({
  //   desiredAction: PERMISSION_ACTIONS.X,
  //   desiredResource: PERMISSION_RESOURCES.PIPELINES,
  //   ...user,
  // });

  return (
    <Route
      header={{
        icon: 'bar-chart',
        rightContainer: (
          <ButtonGroups>
            <ToolbarButton
              active={showFilters || filters.filterApplied}
              icon={filters.filterApplied ? 'filter' : 'menu'}
              label="Filters"
              onClick={() => setShowFilters(!showFilters)}
            />
            {hasWritePermission && (
              <ToolbarButton
                icon="plus"
                label={`Create ${config.nomenclature.Pipeline}`}
                link={`/${config.nomenclature.pipelines}/create`}
              />
            )}
          </ButtonGroups>
        ),
        title: 'Dashboard',
        type: `${config.nomenclature.Pipeline}`,
      }}
      isPrivate
    >
      <DashboardFilterForm
        filters={filters}
        page={pageCriteria}
        showFilters={showFilters}
        sort={sortCriteria}
      />
      <Query
        fetchPolicy="network-only"
        notifyOnNetworkStatusChange
        query={allPipelineQuery}
        variables={{ searchBody: JSON.stringify(query) }}
      >
        {({ data, loading }) => {
          const pipelines = get(data, 'allPipeline', []);
          const pipeLineIds = pipelines.map((i) => i.id);

          return (
            <>
              <Card m={0} p={5}>
                <Query
                  pollInterval="1000"
                  query={pipelineStatus}
                  skip={pipeLineIds.length === 0}
                  variables={{ ids: pipeLineIds }}
                >
                  {({ data: statusData }) => {
                    let pipelineStatus = {};
                    get(statusData, 'pipelineStatus', []).forEach((i) => {
                      pipelineStatus = { ...pipelineStatus, [i.id]: i.status };
                    });
                    return (
                      <PipelineTable
                        data={pipelines.map((pipeline) => ({
                          ...pipeline,
                          status: pipelineStatus[pipeline.id],
                        }))}
                        loading={loading}
                        user={user}
                      />
                    );
                  }}
                </Query>
                <TagTooltipBox />
              </Card>
              <DashboardPaginator
                filters={{
                  page: pageCriteria.offset,
                }}
                goToNextPage={() =>
                  navigate(
                    setDashboardURL({
                      filters,
                      filterValues: filters.values,
                      page: {
                        offset: pageCriteria.offset + pageCriteria.pageSize,
                        pageSize: pageCriteria.pageSize,
                      },
                      pathname: location.pathname,
                      sort: sortCriteria,
                    }),
                    { replace: true }
                  )
                }
                goToPreviousPage={() =>
                  navigate(
                    setDashboardURL({
                      filters,
                      filterValues: filters.values,
                      page: {
                        offset: pageCriteria.offset - pageCriteria.pageSize,
                        pageSize: pageCriteria.pageSize,
                      },
                      pathname: location.pathname,
                      sort: sortCriteria,
                    }),
                    { replace: true }
                  )
                }
                isClientSidePagination
                pageTotal={pipelines.length}
              />
            </>
          );
        }}
      </Query>
    </Route>
  );
};

PipelineDashboardPageDummy.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string,
    search: PropTypes.string,
  }).isRequired,
  navigate: PropTypes.func.isRequired,
};

export default PipelineDashboardPageDummy;

import Box from '@shoreag/base/Box';
import Button from '@shoreag/base/Button';
import PropTypes from 'prop-types';
import React from 'react';
import ReactGA from 'react-ga';
import Sidebar from '../Sidebar';
import config from '../../config.json';
import generateUniqStepName from '../../utilities/generate-uniq-step-name';

const PipelineAvailableStepsFormSection = ({ formContext, steps }) => (
  <Sidebar mt="md" title={`Available ${config.nomenclature.Steps}`}>
    {steps.map(({ displayName, stepType }) => (
      <Button
        key={stepType}
        alignItems="center"
        color="primary"
        display="flex"
        fontSize="sm"
        onClick={() => {
          formContext.form.mutators.push('steps', {
            name: generateUniqStepName(formContext, stepType),
            stepType,
          });

          ReactGA.event({
            action: `${config.nomenclature.Pipeline} ${config.nomenclature.Step} Added`,
            category: config.nomenclature.Pipelines,
            label: stepType,
          });
        }}
        py="xs"
        simple
        textAlign="left"
        textDecoration="none"
      >
        <Box
          alignItems="center"
          bg="primary"
          color="white"
          display="flex"
          fontSize="sm"
          height="1.4rem"
          justifyContent="center"
          minWidth="1.4rem"
          mr="sm"
          sx={{ borderRadius: '50%' }}
        >
          +
        </Box>
        {displayName}
      </Button>
    ))}
  </Sidebar>
);

PipelineAvailableStepsFormSection.propTypes = {
  formContext: PropTypes.shape({
    form: PropTypes.shape({
      mutators: PropTypes.shape({
        push: PropTypes.func,
      }),
    }),
  }).isRequired,
  steps: PropTypes.arrayOf(
    PropTypes.shape({
      stepType: PropTypes.string,
    })
  ).isRequired,
};

export default PipelineAvailableStepsFormSection;

import React from 'react';
import Box from '@shoreag/base/Box';
import Card from '@shoreag/base/Card';
import FormStepHeading from '../FormStepHeading';
import TagsFormSection from '../TagsFormSection';
import config from '../../config.json';

const PipelineTagsFormSection = () => (
  <Card mt="md">
    <Box display="flex" justifyContent="space-between">
      <FormStepHeading>Tags</FormStepHeading>
      <Box as="p" color="textSubtle" fontSize="xs" mb="md">
        Add tags to help categorize your {config.nomenclature.pipeline}.
      </Box>
    </Box>
    <TagsFormSection />
  </Card>
);

export default PipelineTagsFormSection;

import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import ReactGA from 'react-ga';
import get from 'lodash/get';
import { AuthContext } from '@shoreag/auth';
import { FORM_ERROR } from 'final-form';
import { Form as FinalForm } from 'react-final-form';
import { Mutation } from 'react-apollo';
import FormError from '../FormError';
import NotFoundPage from '../../routes/default/404';
import PipelineDetailsFormSection from '../PipelineDetailsFormSection';
import Route from '../Route';
import SubmitButton from '../SubmitButton';
import SubtleText from '../SubtleText';
import config from '../../config.json';
import createPipelineMutation from '../../graphql/mutations/create-pipeline.gql';
import isPermitted from '../../utilities/is-permitted';
import {
  PERMISSION_ACTIONS,
  PERMISSION_RESOURCES,
  PIPELINE_TYPES,
} from '../../utilities/constants';

const PipelineCreatePage = ({ navigate }) => {
  const { user } = useContext(AuthContext);

  if (
    !isPermitted({
      desiredAction: PERMISSION_ACTIONS.W,
      desiredResource: PERMISSION_RESOURCES.PIPELINES,
      ...user,
    })
  ) {
    return <NotFoundPage />;
  }

  return (
    <Route
      header={{
        icon: 'workflow',
        rightContainer: <SubtleText>* indicates required</SubtleText>,
        title: `Create ${config.nomenclature.Pipeline}`,
        type: config.nomenclature.Pipeline,
      }}
      isPrivate
    >
      <Mutation mutation={createPipelineMutation}>
        {(createPipeline) => (
          <FinalForm
            onSubmit={async (values) => {
              const updatedValues = values.copyPipelineCode
                ? {
                    ...values,
                    fromPipelineCode: values.copyPipelineCode,
                    fromPipelineVersion: values.copyPipelineVersionId,
                    versionNumber: 1,
                  }
                : values;

              try {
                const {
                  data: {
                    createPipeline: { id },
                  },
                } = await createPipeline({
                  variables: {
                    ...updatedValues,
                    pipelineType:
                      values.pipelineType || PIPELINE_TYPES.DATA_EXCHANGE,
                  },
                });

                ReactGA.event({
                  action: `${config.nomenclature.Pipeline} Created`,
                  category: config.nomenclature.Pipelines,
                  label: id,
                });

                navigate(
                  `/${config.nomenclature.pipelines}/${id}/edit?new=true`
                );
              } catch (e) {
                return {
                  [FORM_ERROR]: get(e, 'graphQLErrors[0].message', e.message),
                };
              }
            }}
            render={({ ...formContext }) => (
              <form onSubmit={formContext.handleSubmit}>
                <PipelineDetailsFormSection formContext={formContext} />
                <FormError>{formContext.submitError}</FormError>
                <SubmitButton mt="lg" submitting={formContext.submitting}>
                  Continue
                </SubmitButton>
              </form>
            )}
          />
        )}
      </Mutation>
    </Route>
  );
};

PipelineCreatePage.propTypes = {
  navigate: PropTypes.func.isRequired,
};

export default PipelineCreatePage;

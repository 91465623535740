const generateUniqStepName = (formContext, stepName, stepNum = 2) =>
  formContext.values.steps.some((s) => s.name === stepName)
    ? generateUniqStepName(
        formContext,
        `${stepNum > 2 ? stepName.replace(/-\d+$/, '') : stepName}-${stepNum}`,
        stepNum + 1
      )
    : stepName;

export default generateUniqStepName;

import PropTypes from 'prop-types';
import React from 'react';
import { ApolloConsumer } from 'react-apollo';
import { Field } from 'react-final-form';
import { required } from '@shoreag/validations';
import { get } from 'lodash';
import Card from '@shoreag/base/Card';
import Dropdown from '@shoreag/base/Dropdown';
import MultiSelectDropdown from '../MultiSelectDropdown';
import allRecursiveSchemaDatumQuery from '../../graphql/queries/all-recursive-schema-datum.gql';
import allDatasetSchemaDatumQuery from '../../graphql/queries/all-dataset-schema-datum.gql';
import PillsGroup from '../PillsGroup';

const AllColumnsSelectFormSection = ({
  formContext,
  isRequired,
  label,
  isMulti,
  name,
  recursive,
  schemaId,
}) => {
  const parameters = get(formContext.values, name, []);
  return (
    <ApolloConsumer>
      {(client) => (
        <Card sx={{ bg: 'gray1', boxShadow: 'none', p: 4 }}>
          <Field
            component={isMulti ? MultiSelectDropdown : Dropdown}
            label={label}
            loadOptions={async () => {
              const {
                data: { allDatasetSchemaDatum, allRecursiveSchemaDatum },
              } = await client.query({
                query: recursive
                  ? allRecursiveSchemaDatumQuery
                  : allDatasetSchemaDatumQuery,
                variables: {
                  id: schemaId,
                },
              });

              return (allDatasetSchemaDatum || allRecursiveSchemaDatum).map(
                ({ fieldName }) => ({
                  label: fieldName,
                  value: fieldName,
                })
              );
            }}
            name={name}
            validate={isRequired ? required : null}
            wrapperSx={{ maxWidth: '100%' }}
          />
          {isMulti && parameters !== 'none' && parameters?.length > 0 && (
            <PillsGroup
              data={parameters.map((parameter) => ({ label: parameter }))}
              onRemove={(parameter) =>
                formContext.form.change(
                  name,
                  parameters.filter((p) => p !== parameter.label)
                )
              }
              wrapperSx={{
                py: 4,
              }}
            />
          )}
        </Card>
      )}
    </ApolloConsumer>
  );
};

AllColumnsSelectFormSection.propTypes = {
  formContext: PropTypes.shape({
    form: PropTypes.shape({ change: PropTypes.func }),
    values: PropTypes.shape({}),
  }).isRequired,
  isMulti: PropTypes.bool,
  isRequired: PropTypes.bool,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  recursive: PropTypes.bool.isRequired,
  schemaId: PropTypes.string.isRequired,
};

AllColumnsSelectFormSection.defaultProps = {
  isMulti: false,
  isRequired: true,
};

export default AllColumnsSelectFormSection;

import Box from '@shoreag/base/Box';
import Card from '@shoreag/base/Card';
import Dropdown from '@shoreag/base/Dropdown';
import Input from '@shoreag/base/Input';
import PropTypes from 'prop-types';
import React from 'react';
import get from 'lodash/get';
import Table from '@shoreag/base/Table';
import { Field } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import { composeValidations, required } from '@shoreag/validations';
import AddButton from '../AddButton';
import FormStepHeading from '../FormStepHeading';
import RemoveButton from '../RemoveButton';
import TableWrapper from '../TableWrapper';
import { PIPELINE_INPUT_TYPES } from '../../utilities/constants';
import { lettersNumbersDashesAndUnderscores } from '../../utilities/validations';

const PipelineInputsFormSection = ({ formContext }) => {
  const autoTriggerPipelines = get(formContext.values, 'autoTrigger') === 'yes';

  const inputs = formContext.values.inputs.filter((p) => p.name && p.type);

  return (
    <Card mt="md">
      <Box display="flex" justifyContent="space-between">
        <FormStepHeading>Inputs</FormStepHeading>
        <Box as="p" color="textSubtle" fontSize="xs" mb="md">
          Required inputs at run time.
        </Box>
      </Box>
      <FieldArray name="inputs">
        {({ fields }) => (
          <>
            {fields.map((name, index) => (
              <Box key={name} alignItems="flex-start" display="flex" mb="md">
                <Box mr="md">
                  <Field
                    component={Input}
                    name={`${name}.name`}
                    placeholder="Name"
                    validate={composeValidations(
                      required,
                      lettersNumbersDashesAndUnderscores
                    )}
                  />
                </Box>
                <Box width="195px">
                  <Field
                    component={Dropdown}
                    name={`${name}.type`}
                    options={Object.values(PIPELINE_INPUT_TYPES)
                      .filter(
                        (type) =>
                          ![
                            PIPELINE_INPUT_TYPES.COLUMN,
                            PIPELINE_INPUT_TYPES.NEW_COLUMN,
                          ].includes(type)
                      )
                      .filter((type) =>
                        autoTriggerPipelines
                          ? type === PIPELINE_INPUT_TYPES.DATASET
                          : true
                      )
                      .map((t) => ({
                        label: t,
                        value: t,
                      }))}
                    placeholder="Type"
                    validate={required}
                    wrapperSx={{ mt: 0 }}
                  />
                </Box>
                <RemoveButton onClick={() => fields.remove(index)}>
                  Remove
                </RemoveButton>
              </Box>
            ))}
            {!autoTriggerPipelines && (
              <Box display="flex" justifyContent="flex-end">
                <AddButton
                  onClick={() => fields.push({ name: '', type: '' })}
                />
              </Box>
            )}
          </>
        )}
      </FieldArray>
      {!!inputs.length && (
        <Box mt="lg">
          <TableWrapper>
            <Table
              header={['Output Key', 'Output Type']}
              rows={inputs.map((o) => [`$$pipelineInputs.${o.name}`, o.type])}
            />
          </TableWrapper>
        </Box>
      )}
    </Card>
  );
};

PipelineInputsFormSection.propTypes = {
  formContext: PropTypes.shape({
    values: PropTypes.shape({
      inputs: PropTypes.arrayOf(
        PropTypes.shape({ name: PropTypes.string, type: PropTypes.string })
      ),
    }),
  }).isRequired,
};

export default PipelineInputsFormSection;

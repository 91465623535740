import React from 'react';
import PropTypes from 'prop-types';
import { Query, useQuery } from 'react-apollo';
import { get } from 'lodash';
import Box from '@shoreag/base/Box';
import Spinner from '@shoreag/base/Spinner';
import stripTypename from '@shoreag/helpers/strip-typename';
import datasetPartnerIdQuery from '../../graphql/queries/dataset-partner-id.gql';
import partnerGroupAssociationInfoQuery from '../../graphql/queries/partner-group-association-info.gql';
import { parsePartner } from '../PartnerOnBoardingSteps/utilities/helper';
import enhanceNestedKeyValue from '../../utilities/enhance-nested-key-value';
import { DELIVERY_DETAILS_ORDER_LIST } from '../../utilities/constants';
import TableWrapper from '../TableWrapper';
import KeyValuePairsNested from '../KeyValuePairsNested';

const GroupAssociationView = ({ datasetId }) => {
  const { data: datasetData, loading } = useQuery(datasetPartnerIdQuery, {
    fetchPolicy: 'network-only',
    skip: !datasetId,
    variables: { id: datasetId },
  });
  if (loading) return <Spinner />;
  const partnerId = get(datasetData, 'dataset.partner.id', null);
  return (
    <Query
      fetchPolicy="network-only"
      query={partnerGroupAssociationInfoQuery}
      variables={{
        id: partnerId,
      }}
    >
      {({ data: partnerData, loading }) => {
        if (loading) return <Spinner />;
        const partner = get(partnerData, 'partner', {});
        const partnerInfo = parsePartner({
          isViewOnly: true,
          partner,
        });
        const groupAssociation = get(partnerInfo, 'groupAssociation', null);
        const groupAssociationData = groupAssociation
          ? Object.entries(
              enhanceNestedKeyValue(stripTypename(groupAssociation))
            )?.sort(
              (a, b) =>
                DELIVERY_DETAILS_ORDER_LIST.indexOf(a[0]) -
                DELIVERY_DETAILS_ORDER_LIST.indexOf(b[0])
            )
          : null;
        return groupAssociationData ? (
          <>
            <Box as="h3" mt={5}>
              Group Association
            </Box>
            <TableWrapper>
              <KeyValuePairsNested
                allowTableView
                pairs={groupAssociationData}
              />
            </TableWrapper>
          </>
        ) : null;
      }}
    </Query>
  );
};

GroupAssociationView.propTypes = {
  datasetId: PropTypes.string.isRequired,
};

GroupAssociationView.defaultProps = {};

export default GroupAssociationView;

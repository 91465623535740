import AutoSave from '@shoreag/base/AutoSave';
import Box from '@shoreag/base/Box';
import Button from '@shoreag/base/Button';
import Input from '@shoreag/base/Input';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import Table from '@shoreag/base/Table';
import get from 'lodash/get';
import noop from 'lodash/noop';
import { Field, Form } from 'react-final-form';
import { Query } from 'react-apollo';
import TableWrapper from '../TableWrapper';
import errorLogQuery from '../../graphql/queries/error-log.gql';
import { SEARCH_TYPES } from '../../utilities/constants';

const ErrorLogTable = ({ headingProps, id, limit }) => {
  const [errorsExist, setErrorsExist] = useState(false);
  const [moreErrorsExist, setMoreErrorsExist] = useState(false);
  const [query, setQuery] = useState('');

  return (
    <Form
      onSubmit={noop}
      render={() => (
        <>
          <AutoSave save={(values) => setQuery(values.query)} />
          <Query
            notifyOnNetworkStatusChange
            query={errorLogQuery}
            variables={{
              id,
              limit,
              query,
              searchType: SEARCH_TYPES.ERROR_MESSAGE,
            }}
          >
            {({ data, fetchMore, loading }) => {
              const errors = get(data, 'errorLog', []);

              setMoreErrorsExist(
                !!errors.length && errors.length % limit === 0
              );

              if (!errorsExist) {
                if (errors.length) setErrorsExist(true);
                return null;
              }

              return (
                <>
                  <Box as="h3" mt="6" {...headingProps}>
                    Validation Errors
                  </Box>
                  <Field
                    component={Input}
                    name="query"
                    placeholder="Filter errors by message..."
                    width="100%"
                    wrapperSx={{ mt: 4 }}
                  />
                  <TableWrapper>
                    <Table
                      header={['Message', 'Field Name', 'Record Number']}
                      isLoading={loading}
                      rows={errors.map((e) => [
                        e.errorMessage,
                        e.columnName,
                        e.rowNumber,
                      ])}
                    />
                  </TableWrapper>
                  {moreErrorsExist && (
                    <Button
                      mt={6}
                      mx="auto"
                      onClick={() =>
                        fetchMore({
                          updateQuery: (
                            previousResult,
                            { fetchMoreResult }
                          ) => {
                            if (!fetchMoreResult.errorLog.length) {
                              setMoreErrorsExist(false);
                            }

                            return {
                              ...fetchMoreResult,
                              errorLog: [
                                ...previousResult.errorLog,
                                ...fetchMoreResult.errorLog,
                              ],
                            };
                          },
                          variables: { offset: errors.length },
                        })
                      }
                      submitting={loading}
                      width="submitButton"
                    >
                      Load More
                    </Button>
                  )}
                </>
              );
            }}
          </Query>
        </>
      )}
    />
  );
};

ErrorLogTable.propTypes = {
  headingProps: PropTypes.shape({}),
  id: PropTypes.string.isRequired,
  limit: PropTypes.string,
};

ErrorLogTable.defaultProps = {
  headingProps: {},
  limit: '10',
};

export default ErrorLogTable;

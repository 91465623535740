import React, { useContext } from 'react';
import {
  useReactTable,
  getCoreRowModel,
  flexRender,
  getExpandedRowModel,
} from '@tanstack/react-table';
import Box from '@shoreag/base/Box';
import PropTypes from 'prop-types';
import { ThemeContext } from 'styled-components';
import Spinner from '@shoreag/base/Spinner';

const ReactAdvanceTable = ({
  cellSx,
  containerSx,
  columns,
  data,
  tableSx,
  headerTrSx,
  trSx,
  tdSx,
  thSx,
  tableOptions,
  renderEmpty,
  isLoading,
}) => {
  const theme = useContext(ThemeContext);

  const table = useReactTable({
    columns,
    data,
    getCoreRowModel: getCoreRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    getSubRows: (row) => row.subRows,
    ...tableOptions,
  });

  const headerGroups = table.getHeaderGroups();
  const { rows } = table.getRowModel();

  return (
    <>
      <Box sx={containerSx}>
        <Box
          as="table"
          sx={{
            borderCollapse: 'collapse',
            borderRadius: 2,
            fontSize: 2,
            lineHeight: '1.4em',
            width: '100%',
            ...tableSx,
          }}
        >
          <thead>
            {headerGroups.map((headerGroup) => {
              return (
                <Box
                  as="tr"
                  sx={{
                    borderBottomStyle: 'solid',
                    borderColor: 'grays.2',
                    borderWidth: '1px',
                    ...headerTrSx,
                  }}
                >
                  {headerGroup.headers.map((header) => (
                    <Box
                      as="th"
                      sx={{
                        fontSize: 2,
                        fontWeight: 'bold',
                        textAlign: 'left',
                        ...cellSx,
                      }}
                    >
                      <Box
                        sx={{
                          p: 5,
                          ...thSx,
                        }}
                      >
                        {header.isPlaceholder
                          ? null
                          : flexRender(
                              header.column.columnDef.header,
                              header.getContext()
                            )}
                      </Box>
                    </Box>
                  ))}
                </Box>
              );
            })}
          </thead>
          <tbody>
            {!isLoading ? (
              <>
                {rows.map((row) => {
                  const isExpanded =
                    (row.subRows.length > 0 && row.getIsExpanded()) ||
                    row.depth > 0;

                  return (
                    <Box
                      key={row.id}
                      as="tr"
                      className={isExpanded ? 'isExpanded' : ''}
                      sx={{
                        '&:nth-child(even)': {
                          '&:hover': trSx['&:hover'],
                          bg: theme.colors.gray1,
                        },
                        color: 'text.subtle',
                        cursor: 'default',
                        ...(isExpanded
                          ? {
                              bg: theme.colors.gray3,
                              color: trSx.color,
                            }
                          : {}),
                        ...trSx,
                      }}
                    >
                      {row.getVisibleCells().map((cell) => (
                        <Box
                          key={cell.id}
                          as="td"
                          sx={{
                            px: 5,
                            py: 4,
                            ...cellSx,
                            ...tdSx,
                          }}
                        >
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext()
                          )}
                        </Box>
                      ))}
                    </Box>
                  );
                })}
              </>
            ) : (
              <Box as="tr" sx={trSx}>
                <Box
                  as="td"
                  colSpan={headerGroups[0].headers.length}
                  sx={{ textAlign: 'center', ...tdSx }}
                >
                  {isLoading ? <Spinner /> : renderEmpty}
                </Box>
              </Box>
            )}
          </tbody>
        </Box>
      </Box>
    </>
  );
};

ReactAdvanceTable.propTypes = {
  cellSx: PropTypes.shape({}),
  columns: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})])
  ).isRequired,
  containerSx: PropTypes.shape({}),
  data: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})])
  ).isRequired,
  headerTrSx: PropTypes.shape({}),
  isLoading: PropTypes.bool,
  renderEmpty: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  tableOptions: PropTypes.shape({}),
  tableSx: PropTypes.shape({}),
  tdSx: PropTypes.shape({}),
  thSx: PropTypes.shape({}),
  trSx: PropTypes.shape({
    '&:hover': PropTypes.shape({}),
    color: PropTypes.string,
  }),
};

ReactAdvanceTable.defaultProps = {
  cellSx: {},
  containerSx: {},
  headerTrSx: {},
  isLoading: false,
  renderEmpty: 'No results.',
  tableOptions: {},
  tableSx: {},
  tdSx: {},
  thSx: {},
  trSx: {},
};

export default ReactAdvanceTable;

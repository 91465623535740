import get from 'lodash/get';
import stripTypename from '@shoreag/helpers/strip-typename';
import { isEmpty } from 'lodash';
import generateRuleList from './generate-rule-list';
import {
  PIPELINE_INPUT_TYPES,
  PIPELINE_STEP_INPUT_TYPE_DEFAULT_VALUES,
  PIPELINE_STEP_TYPES,
} from '../../../utilities/constants';
import {
  COLUMN_MAPPING_TYPES,
  RUN_NOTEBOOK_S3_PREFIX_PATH,
  VALUE_MAPPER_PAYLOAD_KEYS,
} from './constants';

const generateParsePipelineSteps = (
  allStepDefinitions,
  valueMapperDefinitions
) => (step) => {
  const stepDefinition = allStepDefinitions.find(
    (def) => def.stepType === step.stepType
  );

  if (!stepDefinition.input) return { ...step, inputs };

  const parseInputs = JSON.parse(step.inputs);
  /* if (step.stepType === PIPELINE_STEP_TYPES.NORMALIZER) {
    parseInputs = {
      ...JSON.parse(step.inputs),
      columnDictionary: [{ columnMapping: parseInputs.columnDictionary }],
    };
  } */
  const booleanValues = {};

  const inputs = {
    ...stepDefinition.input.reduce((acc, input) => {
      if (
        input.type === PIPELINE_INPUT_TYPES.BOOLEAN &&
        parseInputs[input.name]
      ) {
        booleanValues[input.name] = parseInputs[input.name] === 'true';
      }
      return {
        ...acc,
        [input.name]: PIPELINE_STEP_INPUT_TYPE_DEFAULT_VALUES[input.type],
      };
    }, {}),
    ...parseInputs,
    ...booleanValues,
  };

  if (stepDefinition.stepType === PIPELINE_STEP_TYPES.SCHEMA_VERIFICATION) {
    inputs.sourceSchema = get(inputs, 'sourceSchema.id');
  }

  if (stepDefinition.stepType === PIPELINE_STEP_TYPES.EXPORT_DATA) {
    inputs.sourceSchema = get(inputs, 'sourceSchema.id');
  }

  if (stepDefinition.stepType === PIPELINE_STEP_TYPES.COLUMN_MAPPER) {
    inputs.sourceSchema = get(inputs, 'sourceSchema.id');
    inputs.destinationSchema = get(inputs, 'destinationSchema.id');

    inputs.columnDictionary = Object.entries(
      get(inputs, 'columnDictionary', {})
    );
  }

  if (stepDefinition.stepType === PIPELINE_STEP_TYPES.CLICK_ENROLL_CONVERTER) {
    inputs.destinationSchema = get(inputs, 'destinationSchema.id');
  }

  if (stepDefinition.stepType === PIPELINE_STEP_TYPES.NORMALIZER) {
    inputs.sourceSchema = get(inputs, 'sourceSchema.id');
    inputs.destinationSchema = get(inputs, 'destinationSchema.id');
    inputs.type = get(inputs, 'type', COLUMN_MAPPING_TYPES.FLAT);
    inputs.groupingParameters = get(inputs, 'groupingParameters', []);

    // inputs.parentColumnMapping = Object.entries(
    //   get(inputs, 'parentColumnMapping', {})
    // )
    //   .map(([left, right]) => [right, left])
    //   .filter(([left, right]) => left && right);
    //
    // inputs.childColumnMapping = Object.entries(
    //   get(inputs, 'childColumnMapping', {})
    // )
    //   .map(([left, right]) => [right, left])
    //   .filter(([left, right]) => left && right);
    const isColumnDictionary = get(inputs, 'columnDictionary', []);
    inputs.columnDictionary = isEmpty(isColumnDictionary)
      ? [
          {
            columnMapping: [],
            commonMapping: 'true',
          },
        ]
      : isColumnDictionary.map((mapping) => ({
          ...mapping,
          columnMapping: Object.entries(mapping.columnMapping),
        }));
  }

  if (stepDefinition.stepType === PIPELINE_STEP_TYPES.RUN_NOTEBOOK) {
    inputs.parameters = Object.entries(get(inputs, 'parameters', {})).map(
      ([k, v]) => ({
        replaceString: v,
        searchString: k,
      })
    );
    inputs.script_path = inputs.script_path.replace(
      RUN_NOTEBOOK_S3_PREFIX_PATH,
      ''
    );
  }

  if (stepDefinition.stepType === PIPELINE_STEP_TYPES.VALUE_MAPPER) {
    inputs.sourceSchema = get(inputs, 'sourceSchema.id');

    const regularRuleList = stripTypename(
      get(inputs, `ruleSet.${VALUE_MAPPER_PAYLOAD_KEYS.REGULAR_RULES}`, [])
    );

    const aggregateRuleList = stripTypename(
      get(inputs, `ruleSet.${VALUE_MAPPER_PAYLOAD_KEYS.AGGREGATE_RULES}`, [])
    );

    const variableDefinitionsList = stripTypename(
      get(
        inputs,
        `ruleSet.${VALUE_MAPPER_PAYLOAD_KEYS.VARIABLE_DEFINITIONS}`,
        []
      )
    );

    const {
      aggregateList,
      ruleList,
      variableDefinitionList,
    } = generateRuleList(
      aggregateRuleList,
      regularRuleList,
      valueMapperDefinitions,
      variableDefinitionsList
    );

    if (inputs.ruleSet) {
      inputs.ruleSet[VALUE_MAPPER_PAYLOAD_KEYS.REGULAR_RULES] = ruleList;
      inputs.ruleSet[VALUE_MAPPER_PAYLOAD_KEYS.AGGREGATE_RULES] = aggregateList;
      inputs.ruleSet[
        VALUE_MAPPER_PAYLOAD_KEYS.VARIABLE_DEFINITIONS
      ] = variableDefinitionList;
    }
  }

  return { ...step, inputs };
};

export default generateParsePipelineSteps;

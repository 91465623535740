import Card from '@shoreag/base/Card';
import Input from '@shoreag/base/Input';
import PropTypes from 'prop-types';
import React from 'react';
import ReactGA from 'react-ga';
import Table from '@shoreag/base/Table';
import { Element } from 'react-scroll';
import { Field } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import { capitalCase } from 'change-case';
import { required } from '@shoreag/validations';
import FlagHeading from '../FlagHeading';
import FormStepHeading from '../FormStepHeading';
import PipelineInputComponent from '../PipelineInputComponent';
import RemoveButton from '../RemoveButton';
import TableWrapper from '../TableWrapper';
import config from '../../config.json';
import { PIPELINE_STEP_INPUT_TYPES } from '../../utilities/constants';
import Sidebar from '../Sidebar';

const PipelineStepsFormSection = ({ formContext, steps }) => (
  <FieldArray name="steps">
    {({ fields }) =>
      !!fields.length && (
        <Card mt="md">
          <FormStepHeading mb="-0.5rem">
            {config.nomenclature.Steps}
          </FormStepHeading>
          {fields.map((name, index) => {
            const currentStep = steps.find(
              (s) => s.stepType === fields.value[index].stepType
            );

            const inputReferenceKeys = formContext.values.steps
              .slice(0, index)
              .reduce(
                (outputs, step) => [
                  ...outputs,
                  ...steps
                    .find((fullStep) => fullStep.stepType === step.stepType)
                    .output.map((output) => ({
                      key: `$$${step.name}.outputs.${output.name}`,
                      type: output.type,
                    })),
                ],
                formContext.values.inputs
                  .filter((input) => input.name && input.type)
                  .map((input) => ({
                    key: `$$pipelineInputs.${input.name}`,
                    type: input.type,
                  }))
              );

            // TODO: use to enforce unique step names
            // const allStepNames = formContext.values.steps.reduce(
            //   (stepNames, step) => [...stepNames, ...step.name],
            //   []
            // );

            const dict = formContext.values.steps[index].inputs;
            const visibleArray = [];

            if (currentStep.input) {
              currentStep.input.forEach((row) => {
                let visible = true;

                if (row.when) {
                  const whn = row.when;

                  if (
                    dict &&
                    dict[whn.key] &&
                    !(
                      dict[whn.key] === whn.value ||
                      whn.value.includes(dict[whn.key])
                    )
                  ) {
                    visible = false;
                    const field = `steps[${index}].inputs[${row.name}]`;
                    formContext.form.change(field, whn.nonMatchingValue);
                  }
                }

                visibleArray.push(visible);
              });
            }

            return (
              <Element key={name} name={`step${index}`}>
                <FlagHeading>{currentStep.displayName}</FlagHeading>
                <RemoveButton
                  fontWeight="normal"
                  mr="0"
                  mt="md"
                  onClick={() => {
                    fields.remove(index);

                    ReactGA.event({
                      action: `${config.nomenclature.Pipeline} ${config.nomenclature.Step} Removed`,
                      category: config.nomenclature.Pipelines,
                      label: currentStep.stepType,
                    });
                  }}
                >
                  Remove
                </RemoveButton>
                <Field
                  component={Input}
                  label="Step Name"
                  name={`${name}.name`}
                  validate={required}
                />
                {currentStep.input &&
                  currentStep.input.map((input, ix) =>
                    visibleArray[ix] ? (
                      <PipelineInputComponent
                        key={`${name}.inputs.${input.name}`}
                        allowedValues={
                          input.allowedValuesRef || input.allowedValues
                        }
                        autocompleteItems={
                          input.type === PIPELINE_STEP_INPUT_TYPES.DICTIONARY
                            ? inputReferenceKeys.map((k) => k.key)
                            : inputReferenceKeys
                                .filter((k) => k.type === input.type)
                                .map((k) => k.key)
                        }
                        extraAttributes={
                          JSON.parse(input.extraAttributes) || {}
                        }
                        formContext={formContext}
                        isMulti={input.multiple}
                        isRequired={input.optional !== true}
                        label={capitalCase(input.name)}
                        name={`${name}.inputs.${input.name}`}
                        stepType={currentStep.stepType}
                        type={input.type}
                      />
                    ) : null
                  )}
                <Sidebar
                  buttonSx={{
                    bg: 'white',
                    p: 4,
                  }}
                  isOpen={false}
                  title="Output"
                  titleSx={{
                    color: 'text.subtle',
                    fontSize: 2,
                    fontWeight: 'bold',
                    textTransform: 'uppercase',
                  }}
                  wrapperSx={{
                    border: '1px solid',
                    borderColor: 'border',
                    boxShadow: 'unset',
                    maxWidth: '100%',
                    mt: 'lg',
                    p: 0,
                    textAlign: 'left',
                  }}
                >
                  <TableWrapper sx={{ mt: 0 }}>
                    <Table
                      header={['Output Key', 'Output Type']}
                      rows={currentStep.output.map((o) => [
                        `$$${fields.value[index].name}.outputs.${o.name}`,
                        o.type,
                      ])}
                    />
                  </TableWrapper>
                </Sidebar>
              </Element>
            );
          })}
        </Card>
      )
    }
  </FieldArray>
);

PipelineStepsFormSection.propTypes = {
  formContext: PropTypes.shape({
    form: PropTypes.shape({
      change: PropTypes.func,
    }),
    values: PropTypes.shape({
      inputs: PropTypes.arrayOf(
        PropTypes.shape({ input: PropTypes.string, type: PropTypes.string })
      ),
      steps: PropTypes.arrayOf(
        PropTypes.shape({
          inputs: PropTypes.shape({}),
          stepType: PropTypes.string,
        })
      ),
    }),
  }).isRequired,
  steps: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default PipelineStepsFormSection;

import Autocomplete from '@shoreag/base/Autocomplete';
import Box from '@shoreag/base/Box';
import Dropdown from '@shoreag/base/Dropdown';
import Input from '@shoreag/base/Input';
import PropTypes from 'prop-types';
import React from 'react';
import ToggleSwitch from '@shoreag/base/ToggleSwitch';
import TextArea from '@shoreag/base/TextArea';
import get from 'lodash/get';
import has from 'lodash/has';
import noop from 'lodash/noop';
import styled from 'styled-components';
import { Query } from 'react-apollo';
import { Field } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import { composeValidations, float, required } from '@shoreag/validations';
import Spinner from '@shoreag/base/Spinner';
import ColumnMappingFormSection from '../ColumnMappingFormSection';
import DatasetSelectFormSection from '../DatasetSelectFormSection';
import PipelineDictionaryComponent from './PipelineDictionaryComponent';
import PipelineS3FileComponent from './PipelineS3FileComponent';
import PipelineValueMapperComponent from './PipelineValueMapperComponent';
import SchemaSelectFormSection from '../SchemaSelectFormSection';
import TagsFormSection from '../TagsFormSection';
import {
  END_OF_NAME_REGEX,
  PIPELINE_STEP_INPUT_TYPES,
  PIPELINE_STEP_TYPES,
} from '../../utilities/constants';
import { COLUMN_MAPPING_TYPES } from '../PipelineEditPage/utilities/constants';
import {
  inputReferenceKey,
  maximum,
  minimum,
} from '../../utilities/validations';
import usePrevious from '../../utilities/get-previous-props-and-state';
import AllColumnsSelectFormSection from '../AllColumnsSelectFormSection';
import AddButton from '../AddButton';
import RemoveButton from '../RemoveButton';
import FlagHeading from '../FlagHeading';
import columnMapperSchemaQuery from '../../graphql/queries/column-mapper-schema.gql';
import normalizerSchemaQuery from '../../graphql/queries/normalizer-schema-query.gql';

const ListFormSectionContainer = styled.div`
  margin: ${(p) => p.theme.space.lg} -${(p) => p.theme.space.lg} 0;
  padding: ${(p) => p.theme.space.md} ${(p) => p.theme.space.lg};
  border: solid 1px ${(p) => p.theme.colors.grays[0]};
`;

const PipelineInputComponent = ({
  allowedValues,
  autocompleteItems,
  extraAttributes,
  formContext,
  isMulti,
  isRequired,
  label,
  name,
  stepType,
  type,
}) => {
  const { min, max } = extraAttributes;
  switch (type) {
    case PIPELINE_STEP_INPUT_TYPES.BOOLEAN: {
      return (
        <Field
          component={ToggleSwitch}
          errorSx={{
            ml: 4,
            mt: 0,
          }}
          inputWrapperSx={{
            mr: 4,
            order: '-1',
          }}
          label={label}
          labelSx={{
            ml: 0,
            order: 'unset',
          }}
          name={name}
          type="checkbox"
          wrapperSx={{
            alignItems: 'center',
            display: 'flex',
          }}
          {...extraAttributes}
        />
      );
    }

    case PIPELINE_STEP_INPUT_TYPES.INTEGER: {
      return (
        <Field
          component={Input}
          label={label}
          name={name}
          type="number"
          validate={composeValidations(
            isRequired ? required : noop,
            minimum(min),
            maximum(max)
          )}
          {...extraAttributes}
        />
      );
    }

    case PIPELINE_STEP_INPUT_TYPES.FLOAT: {
      return (
        <Field
          component={Input}
          label={label}
          name={name}
          validate={composeValidations(required, float(2))}
          {...extraAttributes}
        />
      );
    }

    case PIPELINE_STEP_INPUT_TYPES.DATASET:
    case PIPELINE_STEP_INPUT_TYPES.STRING: {
      if (Array.isArray(allowedValues)) {
        return (
          <Field
            component={Dropdown}
            label={label}
            name={name}
            options={allowedValues.map((row) => {
              return {
                label: row,
                value: row,
              };
            })}
            {...extraAttributes}
          />
        );
      }

      if (type === PIPELINE_STEP_INPUT_TYPES.DATASET && !autocompleteItems) {
        return (
          <DatasetSelectFormSection
            label={label}
            name={name}
            validate={isRequired ? required : noop}
            {...extraAttributes}
          />
        );
      }

      if (type === PIPELINE_STEP_INPUT_TYPES.STRING && label === 'Keyfile') {
        return (
          <Field
            component={TextArea}
            data-cy={name}
            label={label}
            name={name}
            validate={composeValidations(
              isRequired ? required : noop,
              ...(autocompleteItems
                ? [inputReferenceKey(autocompleteItems)]
                : [])
            )}
          />
        );
      }

      return (
        <Field
          component={autocompleteItems ? Autocomplete : Input}
          items={autocompleteItems}
          label={label}
          name={name}
          validate={composeValidations(
            isRequired ? required : noop,
            ...(autocompleteItems ? [inputReferenceKey(autocompleteItems)] : [])
          )}
          {...extraAttributes}
        />
      );
    }

    case PIPELINE_STEP_INPUT_TYPES.SCHEMA: {
      return (
        <SchemaSelectFormSection
          formContext={formContext}
          isRequired={isRequired}
          label={label}
          name={name}
        />
      );
    }

    case PIPELINE_STEP_INPUT_TYPES.COLUMN_LIST: {
      const allowedValuesRef = JSON.parse(allowedValues);
      if (allowedValuesRef?.ref) {
        const schemaLocation = name.replace(
          END_OF_NAME_REGEX,
          `.${allowedValuesRef.ref}`
        );
        const schemaWithVersion = get(formContext.values, schemaLocation);
        return schemaWithVersion ? (
          <AllColumnsSelectFormSection
            formContext={formContext}
            isMulti={isMulti}
            isRequired={isRequired}
            label={label}
            name={name}
            recursive={stepType === PIPELINE_STEP_TYPES.NORMALIZER}
            schemaId={schemaWithVersion}
          />
        ) : null;
      }
      return null;
    }

    case PIPELINE_STEP_INPUT_TYPES.DICTIONARY: {
      if (!get(formContext.values, name)) {
        formContext.form.mutators.push(name, {});
      }
      return (
        <ListFormSectionContainer>
          <PipelineDictionaryComponent
            autocompleteItems={autocompleteItems}
            formContext={formContext}
            label={label}
            name={name}
          />
        </ListFormSectionContainer>
      );
    }

    case PIPELINE_STEP_INPUT_TYPES.S3Path: {
      return (
        <PipelineS3FileComponent
          formContext={formContext}
          isRequired={isRequired}
          label={label}
          name={name}
        />
      );
    }

    case PIPELINE_STEP_INPUT_TYPES.TAGS: {
      return (
        <ListFormSectionContainer>
          <Box color="textSubtle" mb="md">
            {label}
          </Box>
          <TagsFormSection name={name} />
        </ListFormSectionContainer>
      );
    }

    case PIPELINE_STEP_INPUT_TYPES.COLUMN_DICTIONARY:
    case PIPELINE_STEP_INPUT_TYPES.REVERSED_COLUMN_DICTIONARY: {
      const mappingTypeLocation = name.replace(END_OF_NAME_REGEX, '.type');
      const mappingType = get(formContext.values, mappingTypeLocation);

      const destinationSchemaLocation = name.replace(
        END_OF_NAME_REGEX,
        '.destinationSchema'
      );

      const destinationSchema = get(
        formContext.values,
        destinationSchemaLocation
      );

      const destinationSchemaParts = name.replace(
        END_OF_NAME_REGEX,
        '.destinationSchemaParts'
      );

      const destinationSchemaVersion = get(
        formContext.values,
        destinationSchemaParts
      )?.version;

      const sourceSchemaLocation = name.replace(
        END_OF_NAME_REGEX,
        '.sourceSchema'
      );

      const sourceSchema = get(formContext.values, sourceSchemaLocation);

      const sourceSchemaParts = name.replace(
        END_OF_NAME_REGEX,
        '.sourceSchemaParts'
      );

      const sourceSchemaVersion = get(formContext.values, sourceSchemaParts)
        ?.version;

      const prevSchema = usePrevious({ destinationSchema, sourceSchema });
      const prevSourceSchema = prevSchema?.sourceSchema || sourceSchema;
      const prevDestinationSchema =
        prevSchema?.destinationSchema || destinationSchema;
      const isSourceSchemaChanged = prevSourceSchema !== sourceSchema;
      const isDestinationSchemaChanged =
        prevDestinationSchema !== destinationSchema;
      const schemaStatus = {
        isDestinationSchemaChanged,
        isSourceSchemaChanged,
      };

      const isFlatMapping = mappingType === COLUMN_MAPPING_TYPES.FLAT;
      const columnDictionaryLocation = name.replace(
        END_OF_NAME_REGEX,
        '.columnDictionary'
      );
      const columnDictionary = get(
        formContext.values,
        columnDictionaryLocation,
        [
          {
            columnMapping: [],
            commonMapping: 'true',
          },
        ]
      );
      if (isFlatMapping && columnDictionary?.length > 1) {
        formContext.form.change(
          columnDictionaryLocation,
          columnDictionary.filter(
            (columnDictionary) => columnDictionary.commonMapping
          )
        );
      }

      if (
        has(formContext.errors, destinationSchemaLocation) ||
        has(formContext.errors, sourceSchemaLocation) ||
        !destinationSchema ||
        !sourceSchema ||
        !destinationSchemaVersion ||
        !sourceSchemaVersion
      ) {
        return null;
      }
      return (
        <ListFormSectionContainer>
          <Box
            alignItems="center"
            display="flex"
            justifyContent="space-between"
            mb="md"
          >
            <Box color="textSubtle">{label}</Box>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-end',
              }}
            >
              <Box as="p" color="textSubtle" fontSize="xs">
                Map the columns by clicking on them.
              </Box>
              <Box
                sx={{
                  alignItems: 'center',
                  color: 'textSubtle',
                  display: 'flex',
                  fontSize: 'xs',
                  mt: 1,
                }}
              >
                <Box
                  sx={{
                    alignItems: 'center',
                    display: 'flex',
                    mr: 2,
                  }}
                >
                  <Box
                    sx={{
                      bg: 'accent',
                      borderRadius: '9999px',
                      height: '10px',
                      mr: 1,
                      width: '10px',
                    }}
                  />
                  Schema
                </Box>
                <Box
                  sx={{
                    alignItems: 'center',
                    display: 'flex',
                  }}
                >
                  <Box
                    sx={{
                      bg: 'white',
                      border: '1px solid',
                      borderColor: 'border',
                      borderRadius: '9999px',
                      height: '10px',
                      mr: 1,
                      width: '10px',
                    }}
                  />
                  Datum
                </Box>
              </Box>
            </Box>
          </Box>
          <FieldArray name={name}>
            {({ fields }) => {
              const isNormalizer = stepType === PIPELINE_STEP_TYPES.NORMALIZER;
              return (
                <Query
                  fetchPolicy="network-only"
                  query={
                    isNormalizer
                      ? normalizerSchemaQuery
                      : columnMapperSchemaQuery
                  }
                  skip={!(sourceSchema && destinationSchema)}
                  variables={{
                    leftId: sourceSchema,
                    rightId: destinationSchema,
                  }}
                >
                  {({ data, loading }) => {
                    if (loading) {
                      return <Spinner />;
                    }
                    if (!(data.left && data.right)) {
                      return null;
                    }
                    return isNormalizer ? (
                      <>
                        {fields.map((name, index) => {
                          const isCommonMapping = index === 0;
                          return (
                            <ColumnMappingFormSection
                              key={name}
                              commonMapping={isCommonMapping}
                              data={data}
                              destinationSchema={destinationSchema}
                              formContext={formContext}
                              label={
                                !isFlatMapping && (
                                  <Box
                                    sx={{
                                      alignItems: 'center',
                                      display: 'flex',
                                      mb: 5,
                                      mt: 3,
                                    }}
                                  >
                                    <FlagHeading
                                      sx={{
                                        bg: 'accentSecondaryDark',
                                        borderLeftColor: 'accentSecondaryDark',
                                        ml: -5,
                                        mt: 0,
                                      }}
                                    >
                                      {isCommonMapping
                                        ? 'Common Mapping'
                                        : 'Section Mapping'}
                                    </FlagHeading>
                                    {!isCommonMapping && (
                                      <RemoveButton
                                        onClick={() => fields.remove(index)}
                                      >
                                        Remove
                                      </RemoveButton>
                                    )}
                                  </Box>
                                )
                              }
                              // removed mappingRequired for columnMapping and normalizer
                              // mappingRequired={
                              //   type !==
                              //   PIPELINE_STEP_INPUT_TYPES.REVERSED_COLUMN_DICTIONARY
                              // }
                              mappingType={mappingType}
                              name={name}
                              recursive={isNormalizer}
                              schemaStatus={schemaStatus}
                              sourceSchema={sourceSchema}
                            />
                          );
                        })}
                        {(!isFlatMapping ||
                          !(isFlatMapping && fields.length > 0)) && (
                          <Box
                            sx={{
                              display: 'flex',
                              justifyContent: 'flex-end',
                              mt: 4,
                            }}
                          >
                            <AddButton
                              data-cy="addColumnMappingSection"
                              onClick={() => fields.push({})}
                            />
                          </Box>
                        )}
                      </>
                    ) : (
                      <ColumnMappingFormSection
                        data={data}
                        destinationSchema={destinationSchema}
                        fields={fields}
                        formContext={formContext}
                        // removed mappingRequired for columnMapping and normalizer
                        // mappingRequired={
                        //   type !==
                        //   PIPELINE_STEP_INPUT_TYPES.REVERSED_COLUMN_DICTIONARY
                        // }
                        name={name}
                        recursive={isNormalizer}
                        schemaStatus={schemaStatus}
                        sourceSchema={sourceSchema}
                      />
                    );
                  }}
                </Query>
              );
            }}
          </FieldArray>
        </ListFormSectionContainer>
      );
    }

    case PIPELINE_STEP_INPUT_TYPES.RULESET: {
      const sourceSchemaLocation = name.replace(
        END_OF_NAME_REGEX,
        '.sourceSchema'
      );

      const sourceSchema = get(formContext.values, sourceSchemaLocation);
      if (!sourceSchema) return null;

      return (
        <PipelineValueMapperComponent
          formContext={formContext}
          name={name}
          sourceSchema={sourceSchema}
          stepType={stepType}
        />
      );
    }

    default: {
      return null;
    }
  }
};

PipelineInputComponent.propTypes = {
  autocompleteItems: PropTypes.arrayOf(PropTypes.string),
  extraAttributes: PropTypes.shape({}),
  formContext: PropTypes.shape({
    errors: PropTypes.shape({}),
    form: PropTypes.shape({
      change: PropTypes.func,
    }),
    values: PropTypes.shape({}),
  }).isRequired,
  isMulti: PropTypes.bool,
  isRequired: PropTypes.bool.isRequired,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  stepType: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
};

PipelineInputComponent.defaultProps = {
  autocompleteItems: null,
  extraAttributes: {},
  isMulti: false,
};

export default PipelineInputComponent;

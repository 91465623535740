import React from 'react';
import { Router } from '@reach/router';
import NotFoundPage from './404';
import PipelineCreatePage from '../../components/PipelineCreatePage';
import PipelineDashboardPage from '../../components/PipelineDashboardPage';
import PipelineDashboardPageDummy from '../../components/PipelineDashboardPageDummy';
import PipelineDetailsPage from '../../components/PipelineDetailsPage';
import PipelineEditPage from '../../components/PipelineEditPage';
import PipelineExecutionPage from '../../components/PipelineExecutionPage';
import PipelineSchedulePage from '../../components/PipelineSchedulePage';

const WorkflowsRouter = (props) => (
  <Router>
    <PipelineCreatePage {...props} path="/workflows/create" />
    <PipelineDashboardPage {...props} path="/workflows/" />
    <PipelineDashboardPageDummy {...props} path="/workflows/dashboard" />
    <PipelineDetailsPage {...props} path="/workflows/:pipelineId" />
    <PipelineEditPage {...props} path="/workflows/:pipelineId/edit" />
    <PipelineExecutionPage
      {...props}
      path="/workflows/:pipelineId/execution/:executionNumber"
    />
    <PipelineSchedulePage {...props} path="/workflows/:pipelineId/schedule" />
    <NotFoundPage default />
  </Router>
);

export default WorkflowsRouter;

import Box from '@shoreag/base/Box';
import React from 'react';
import PropTypes, { shape } from 'prop-types';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { Link } from 'react-scroll';
import config from '../../config.json';
import Sidebar from '../Sidebar';
import scrollToSection from '../../utilities/scroll-to';

const PipelineWorkflowOverview = ({ formContext }) => (
  <Sidebar title={`${config.nomenclature.Pipeline} Overview`}>
    {formContext.values.steps.length ? (
      <DragDropContext
        onDragEnd={(result) => {
          if (result.destination) {
            const startIndex = result.source.index;
            const endIndex = result.destination.index;
            const list = Array.from(formContext.values.steps);
            const [removed] = list.splice(startIndex, 1);
            list.splice(endIndex, 0, removed);
            formContext.form.change('steps', list);
            if (startIndex !== endIndex) {
              setTimeout(() => {
                scrollToSection(`step${endIndex}`);
              }, 500);
            }
          }
        }}
      >
        <Droppable droppableId="droppable">
          {(provided, snapshot) => (
            <Box
              {...provided.droppableProps}
              ref={provided.innerRef}
              sx={{
                backgroundColor: snapshot.isDraggingOver ? 'grays.0' : '',
                borderRadius: 2,
                boxShadow: (p) =>
                  `inset ${snapshot.isDraggingOver && p.shadows[1]}`,
                mb: -1,
                mx: -2,
                p: 2,
              }}
            >
              {formContext.values.steps.map((step, i) => (
                <Draggable
                  key={`${step.name}-${i}`}
                  draggableId={`${step.name}-${i}`}
                  index={i}
                >
                  {(provided, snapshot) => (
                    <Box
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      sx={{
                        '&:hover': {
                          borderColor: 'accent',
                        },
                        alignItems: 'center',
                        bg: 'white',
                        border: '1px solid',
                        borderColor: 'grays.1',
                        borderRadius: 2,
                        boxShadow: (p) =>
                          `${snapshot.isDragging && p.shadows[0]}`,
                        display: 'inline-flex',
                        mb: 1,
                        width: '100%',
                      }}
                    >
                      <Box
                        {...provided.dragHandleProps}
                        sx={{
                          flex: 'none',
                          p: 3,
                        }}
                      >
                        ⋮⋮
                      </Box>
                      <Box display="flex" my="sm">
                        <Box mr="xs">{i + 1}.</Box>
                        <Box
                          as={Link}
                          duration={200}
                          offset={25}
                          smooth
                          sx={{
                            cursor: 'pointer',
                            display: 'inline-flex',
                            px: 2,
                            textAlign: 'left',
                          }}
                          to={`step${i}`}
                        >
                          {step.name}
                        </Box>
                      </Box>
                    </Box>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </Box>
          )}
        </Droppable>
      </DragDropContext>
    ) : (
      <Box color="textSubtle" fontSize="sm">
        No {config.nomenclature.steps} added.
      </Box>
    )}
  </Sidebar>
);

PipelineWorkflowOverview.propTypes = {
  formContext: PropTypes.shape({
    form: PropTypes.shape({
      change: PropTypes.func,
    }),
    values: PropTypes.shape({
      schemaId: PropTypes.string,
      steps: PropTypes.arrayOf(shape({})),
    }),
  }).isRequired,
};

export default PipelineWorkflowOverview;

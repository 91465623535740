import Box from '@shoreag/base/Box';
import Dropdown from '@shoreag/base/Dropdown';
import PropTypes from 'prop-types';
import React from 'react';
import get from 'lodash/get';
import has from 'lodash/has';
import noop from 'lodash/noop';
import { ApolloConsumer } from 'react-apollo';
import { Field } from 'react-final-form';
import { required } from '@shoreag/validations';
import allSchemaVersionsDropdownQuery from '../../graphql/queries/all-schema-versions-dropdown.gql';
import allSchemaOptimizedQuery from '../../graphql/queries/all-schema-optimized.gql';

const SchemaSelectFormSection = ({ formContext, isRequired, label, name }) => {
  const idPath = `${name}Parts.id`;
  const versionPath = `${name}Parts.version`;

  return (
    <ApolloConsumer>
      {(client) => (
        <>
          <Box color="textSubtle" mb="xxxs" mt="lg">
            {label}
          </Box>
          <Box display="flex" width="100%">
            <Box mr="md" width="65%">
              <Field
                component={Dropdown}
                loadOptions={async (query) => {
                  const {
                    data: { allSchema },
                  } = await client.query({
                    query: allSchemaOptimizedQuery,
                    variables: {
                      name: query,
                      optimized: true,
                    },
                  });

                  return allSchema.map(({ id, name }) => ({
                    label: name,
                    value: id,
                  }));
                }}
                name={idPath}
                onChange={() => formContext.form.change(versionPath, null)}
                placeholder="Schema Name"
                validate={isRequired ? required : noop}
              />
            </Box>
            <Box width="35%">
              {has(formContext.values, idPath) && (
                <Field
                  key={get(formContext.values, idPath)}
                  component={Dropdown}
                  loadOptions={async () => {
                    const {
                      data: { datasetSchema },
                    } = await client.query({
                      query: allSchemaVersionsDropdownQuery,
                      variables: {
                        id: get(formContext.values, idPath),
                      },
                    });

                    const latestVersionNumber = get(
                      datasetSchema,
                      'latestVersionNumber',
                      1
                    );

                    return Array.from(
                      { length: latestVersionNumber },
                      (_, index) => latestVersionNumber - index
                    ).map((versionNumber) => ({
                      label: `${versionNumber}`,
                      value: `${versionNumber}`,
                    }));
                  }}
                  name={versionPath}
                  onChange={(version) =>
                    formContext.form.change(
                      name,
                      `${get(formContext.values, idPath)}:${version}`
                    )
                  }
                  placeholder="Version"
                  validate={required}
                  wrapperSx={{ mt: 0 }}
                />
              )}
            </Box>
          </Box>
        </>
      )}
    </ApolloConsumer>
  );
};

SchemaSelectFormSection.propTypes = {
  formContext: PropTypes.shape({
    form: PropTypes.shape({ change: PropTypes.func }),
    values: PropTypes.shape({}),
  }).isRequired,
  isRequired: PropTypes.bool,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
};

SchemaSelectFormSection.defaultProps = {
  isRequired: true,
};

export default SchemaSelectFormSection;

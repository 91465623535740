import Box from '@shoreag/base/Box';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import ReactGA from 'react-ga';
import Spinner from '@shoreag/base/Spinner';
import arrayMutators from 'final-form-arrays';
import get from 'lodash/get';
import stripTypename from '@shoreag/helpers/strip-typename';
import { FORM_ERROR } from 'final-form';
import { Form as FinalForm } from 'react-final-form';
import { Mutation, Query } from 'react-apollo';
import { parse } from 'query-string';
import { ThemeContext } from 'styled-components';
import FormError from '../FormError';
import NotFoundPage from '../../routes/default/404';
import PipelineAvailableStepsList from './AvailableStepsList';
import PipelineDetailsFormSection from '../PipelineDetailsFormSection';
import PipelineInputsFormSection from '../PipelineInputsFormSection';
import PipelineStepsFormSection from '../PipelineStepsFormSection';
import PipelineTagsFormSection from '../PipelineTagsFormSection';
import Route from '../Route';
import SubmitButton from '../SubmitButton';
import config from '../../config.json';
import formatCaseRules from './utilities/format-case-rules';
import formatPipelineSteps from './utilities/format-pipeline-steps';
import generateParsePipelineSteps from './utilities/generate-parse-pipeline-steps';
import parseUuid from '../../utilities/parse-uuid';
import pipelineEditPageQuery from '../../graphql/queries/pipeline-edit-page.gql';
import updatePipelineMutation from '../../graphql/mutations/update-pipeline.gql';
import { PIPELINE_INPUT_TYPES } from '../../utilities/constants';
import findDuplicateStringsInArray from '../../utilities/find-duplicate-strings-in-array';
import PipelineWorkflowOverview from '../PipelineWorkflowOverview';
import { PIPELINE_AUTOSAVE_STORAGE_KEY } from './utilities/constants';
import setDataInLocalStorage from '../../utilities/set-data-in-local-storage';
import AutoSaveUpdatePipeline from './AutoSaveUpdatePipeline';
import AutoSaveIndicator from './AutoSaveIndicator';

const PipelineEditPage = ({ location, navigate, pipelineId }) => {
  const queryParams = parse(location.search);
  const isNew = get(queryParams, 'new', false);
  const theme = useContext(ThemeContext);
  let isPublished = null;
  return (
    <Route
      header={{
        icon: 'workflow',
        rightContainer: <AutoSaveIndicator pipelineId={pipelineId} />,
        title: `${
          isNew
            ? `Create ${config.nomenclature.Pipeline}`
            : `Edit ${config.nomenclature.Pipeline}`
        }`,
        type: config.nomenclature.Pipeline,
      }}
      isPrivate
    >
      <Query
        query={pipelineEditPageQuery}
        variables={{
          id: pipelineId,
        }}
      >
        {({ data, loading }) => {
          if (loading) return <Spinner />;
          if (!data) return <NotFoundPage />;
          const { code: pipelineCode, version: pipelineVersionNo } = parseUuid(
            data.pipeline?.copiedFrom?.id
          );
          isPublished = data.pipeline.isPublished;

          return (
            <>
              <Mutation mutation={updatePipelineMutation}>
                {(updatePipeline) => (
                  <FinalForm
                    initialValues={stripTypename({
                      ...data.pipeline,
                      autoTrigger: data.pipeline.autoTrigger || undefined,
                      copyPipelineCode: pipelineCode,
                      copyPipelineVersionId: pipelineVersionNo,
                      inputs: [
                        ...data.pipeline.inputs,
                        ...(data.pipeline.inputs.length
                          ? []
                          : [
                              {
                                name: 'dataset',
                                type: PIPELINE_INPUT_TYPES.DATASET,
                              },
                            ]),
                      ],
                      partner: data.pipeline.partner
                        ? data.pipeline.partner.id
                        : undefined,
                      steps: data.pipeline.steps.map(
                        generateParsePipelineSteps(
                          data.allStepDefinitions,
                          data.allValueMapperDefinitions
                        )
                      ),
                    })}
                    keepDirtyOnReinitialize
                    mutators={arrayMutators}
                    onSubmit={async (values) => {
                      const steps = values.steps
                        .map(formatCaseRules(data.allStepDefinitions))
                        .map(formatPipelineSteps);

                      try {
                        const {
                          data: {
                            updatePipeline: { id: newPipelineId },
                          },
                        } = await updatePipeline({
                          optimisticResponse: {
                            __typename: 'Mutation',
                            updatePipeline: {
                              ...data.pipeline,
                              __typename: 'Pipeline',
                              autoTrigger: values.autoTrigger,
                              inputs: values.inputs.map((input) => ({
                                ...input,
                                __typename: 'PipelineInput',
                              })),
                              isPublished: values.isPublished,
                              partner: values.partner,
                              steps: steps.map((step) => ({
                                ...step,
                                __typename: 'PipelineStep',
                              })),
                              tags: values.tags.map((tag) => ({
                                ...tag,
                                __typename: 'Tag',
                              })),
                            },
                          },
                          variables: {
                            autoTrigger: values.autoTrigger,
                            id: pipelineId,
                            inputs: values.inputs,
                            isNewVersion: values.isNewVersion,
                            isPublished: values.isPublished,
                            partner: values.partner,
                            steps,
                            tags: values.tags,
                          },
                        });

                        setDataInLocalStorage({
                          storageKey: PIPELINE_AUTOSAVE_STORAGE_KEY,
                          targetKey: pipelineId,
                          value: '',
                        });

                        ReactGA.event({
                          action: `${config.nomenclature.Pipeline} Edited`,
                          category: config.nomenclature.Pipelines,
                          label: newPipelineId,
                        });

                        navigate(
                          `/${config.nomenclature.pipelines}/${newPipelineId}`
                        );
                      } catch (e) {
                        return {
                          [FORM_ERROR]: get(e, 'graphQLErrors[0].message'),
                        };
                      }
                    }}
                    validate={(values) => {
                      const errors = {};

                      const duplicateFieldNames = findDuplicateStringsInArray(
                        values.steps.map((step) => step.name)
                      );

                      if (duplicateFieldNames.length && values.steps) {
                        errors.steps = values.steps.map((step) =>
                          duplicateFieldNames.includes(step.name)
                            ? { name: 'Must be unique' }
                            : null
                        );
                      }

                      return errors;
                    }}
                  >
                    {(formContext) => (
                      <Box
                        as="form"
                        display="flex"
                        flexDirection={{ md: 'row', none: 'column' }}
                        maxWidth={[
                          'maxWidths.form',
                          null,
                          'maxWidths.largeForm',
                          'maxWidths.content',
                        ]}
                        mx="auto"
                        onSubmit={formContext.handleSubmit}
                        width="100%"
                      >
                        <AutoSaveUpdatePipeline
                          disabled={formContext.values.isPublished}
                          pipelineId={pipelineId}
                          valuesString={JSON.stringify({
                            data,
                            values: {
                              ...formContext.values,
                              isNewVersion: isPublished,
                              isPublished: false,
                            },
                          })}
                        />
                        <Box
                          maxWidth="maxWidths.content"
                          mr={{ md: 'md' }}
                          width="100%"
                        >
                          <PipelineDetailsFormSection
                            disabled
                            formContext={formContext}
                          />
                          <PipelineTagsFormSection />
                          <PipelineInputsFormSection
                            formContext={formContext}
                          />
                          <PipelineStepsFormSection
                            formContext={formContext}
                            steps={data.allStepDefinitions}
                          />
                        </Box>
                        <Box
                          sx={{
                            height: '100%',
                            position: 'sticky',
                            top: theme.sizes.heights.header,
                          }}
                        >
                          <PipelineAvailableStepsList
                            formContext={formContext}
                            steps={data.allStepDefinitions}
                          />
                          <PipelineWorkflowOverview formContext={formContext} />
                          <Box mt="lg" width="100%">
                            <FormError mb="md">
                              {formContext.submitError}
                            </FormError>
                            <SubmitButton
                              disabled={
                                formContext.pristine || formContext.submitting
                              }
                              mt="0"
                              onClick={() => {
                                formContext.form.change(
                                  'isNewVersion',
                                  isPublished
                                );

                                formContext.form.change('isPublished', false);
                              }}
                              submitting={
                                formContext.submitting &&
                                !formContext.values.isPublished
                              }
                              width="100%"
                            >
                              Save as Draft
                            </SubmitButton>
                            <SubmitButton
                              disabled={
                                (formContext.pristine &&
                                  get(data, 'pipeline.isPublished', false)) ||
                                !formContext.values.steps.length ||
                                formContext.submitting
                              }
                              mt="sm"
                              onClick={() => {
                                formContext.form.change(
                                  'isNewVersion',
                                  isPublished
                                );

                                formContext.form.change('isPublished', true);
                              }}
                              submitting={
                                formContext.submitting &&
                                formContext.values.isPublished
                              }
                              width="100%"
                            >
                              Publish
                            </SubmitButton>
                          </Box>
                        </Box>
                      </Box>
                    )}
                  </FinalForm>
                )}
              </Mutation>
            </>
          );
        }}
      </Query>
    </Route>
  );
};

PipelineEditPage.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string,
    search: PropTypes.string,
  }).isRequired,
  navigate: PropTypes.func.isRequired,
  pipelineId: PropTypes.string,
};

PipelineEditPage.defaultProps = {
  pipelineId: null,
};

export default PipelineEditPage;

import Box from '@shoreag/base/Box';
import Card from '@shoreag/base/Card';
import DropdownMenu from '@shoreag/base/DropdownMenu';
import PropTypes from 'prop-types';
import React, { useContext, useState } from 'react';
import Table from '@shoreag/base/Table';
import copy from 'clipboard-copy';
import formatDateTimeConcise from '@shoreag/helpers/format-date-time-concise';
import get from 'lodash/get';
import { AuthContext } from '@shoreag/auth';
import { Query } from 'react-apollo';
import ButtonGroups from '../ButtonGroups';
import DashboardFilterForm from '../DashboardFilterForm';
import DashboardPaginator from '../DashboardPaginator';
import DotsIcon from '../DotsIcon';
import LinkWithVersion from '../LinkWithVersion';
import PipelineTypeLabel from '../PipelineTypeLabel';
import ResponsiveEllipsis from '../ResponsiveEllipsis';
import Route from '../Route';
import TagTooltip from '../TagTooltip';
import TagTooltipBox from '../TagTooltip/TagTooltipBox';
import ToolbarButton from '../ToolbarButton';
import allPipelineQuery from '../../graphql/queries/all-pipeline.gql';
import config from '../../config.json';
import dashboardConstants from '../../utilities/dashboard-constants';
import isPermitted from '../../utilities/is-permitted';
import setActionDropdown from '../../utilities/set-action-dropdown';
import setDashboardURL from '../../utilities/set-dashboard-url';
import {
  PERMISSION_ACTIONS,
  PERMISSION_RESOURCES,
} from '../../utilities/constants';

const PipelineDashboardPage = ({ location, navigate }) => {
  const { user } = useContext(AuthContext);
  const [showFilters, setShowFilters] = useState(false);

  const {
    filters,
    sortCriteria,
    pageCriteria,
  } = dashboardConstants.getCriterias({
    search: location.search,
    type: 'pipeline',
  });

  const query = dashboardConstants.getQuery({
    filters,
    page: pageCriteria,
    sort: sortCriteria,
    user,
  });

  const hasWritePermission = isPermitted({
    desiredAction: PERMISSION_ACTIONS.W,
    desiredResource: PERMISSION_RESOURCES.PIPELINES,
    ...user,
  });

  const hasExecutePermission = isPermitted({
    desiredAction: PERMISSION_ACTIONS.X,
    desiredResource: PERMISSION_RESOURCES.PIPELINES,
    ...user,
  });

  return (
    <Route
      header={{
        icon: 'workflow',
        rightContainer: (
          <ButtonGroups>
            <ToolbarButton
              active={showFilters || filters.filterApplied}
              icon={filters.filterApplied ? 'filter' : 'menu'}
              label="Filters"
              onClick={() => setShowFilters(!showFilters)}
            />
            {hasWritePermission && (
              <ToolbarButton
                icon="plus"
                label={`Create ${config.nomenclature.Pipeline}`}
                link={`/${config.nomenclature.pipelines}/create`}
              />
            )}
          </ButtonGroups>
        ),
        title: 'Dashboard',
        type: `${config.nomenclature.Pipeline}`,
      }}
      isPrivate
    >
      <DashboardFilterForm
        filters={filters}
        page={pageCriteria}
        showFilters={showFilters}
        sort={sortCriteria}
      />
      <Query
        fetchPolicy="network-only"
        notifyOnNetworkStatusChange
        query={allPipelineQuery}
        variables={{ searchBody: JSON.stringify(query) }}
      >
        {({ data, loading }) => {
          const pipelines = get(data, 'allPipeline', []);

          return (
            <>
              <Card m={0} p={5}>
                <Table
                  header={[
                    { label: 'Name', value: 'result.data.info.displayName' },
                    { label: 'Type', value: 'result.data.info.pipelineType' },
                    {
                      label: 'Description',
                      value: 'result.data.info.description',
                    },
                    'Partner Name',
                    'Auto Trigger',
                    <Box textAlign="center">{config.nomenclature.Steps}</Box>,
                    { label: 'Updated', value: 'result.lastUpdated' },
                    '',
                  ]}
                  isLoading={loading}
                  onSortUpdate={(sortBy, orderBy) => {
                    return navigate(
                      setDashboardURL({
                        filters,
                        filterValues: filters.values,
                        page: pageCriteria,
                        pathname: location.pathname,
                        sort: {
                          sortBy,
                          sortDirection: orderBy,
                        },
                      }),
                      { replace: true }
                    );
                  }}
                  orderBy={sortCriteria.sortDirection}
                  rows={pipelines.map((pipeline) => {
                    const {
                      autoTrigger,
                      deactivated,
                      description,
                      displayName,
                      id,
                      isPublished,
                      lastUpdated,
                      partner,
                      pipelineType,
                      steps,
                      versionNumber,
                    } = pipeline;

                    const allowScedule =
                      hasExecutePermission && !deactivated && isPublished;
                    const {
                      dropdownItems,
                      dropdownActions,
                    } = setActionDropdown([
                      {
                        action: `/${config.nomenclature.pipelines}/${id}`,
                        display: true,
                        label: ['view', 'View'],
                      },
                      {
                        action: `/${config.nomenclature.pipelines}/${id}/schedule`,
                        display: allowScedule,
                        label: ['clock', 'Schedule'],
                      },
                      {
                        action: () => copy(id),
                        display: true,
                        label: ['copy', 'Copy ID'],
                      },
                    ]);

                    return [
                      <LinkWithVersion
                        link={`/${config.nomenclature.pipelines}/${id}`}
                        name={displayName}
                        version={versionNumber}
                      />,
                      <PipelineTypeLabel pipelineType={pipelineType} />,
                      <ResponsiveEllipsis text={description} />,
                      partner?.partnerName || '-',
                      autoTrigger || '',
                      <TagTooltip
                        data-tip-disable={!steps.length}
                        id="tags"
                        sx={{ textAlign: 'center' }}
                        tags={steps.map((step) => step.name)}
                      >
                        {String(steps.length)}
                      </TagTooltip>,
                      formatDateTimeConcise(lastUpdated),
                      <Box sx={{ float: 'right' }}>
                        <DropdownMenu
                          button={<DotsIcon />}
                          itemActions={dropdownActions}
                          items={dropdownItems}
                        />
                      </Box>,
                    ];
                  })}
                  sortBy={sortCriteria.sortBy}
                />
                <TagTooltipBox />
              </Card>
              <DashboardPaginator
                filters={{
                  page: pageCriteria.offset,
                }}
                goToNextPage={() =>
                  navigate(
                    setDashboardURL({
                      filters,
                      filterValues: filters.values,
                      page: {
                        offset: pageCriteria.offset + pageCriteria.pageSize,
                        pageSize: pageCriteria.pageSize,
                      },
                      pathname: location.pathname,
                      sort: sortCriteria,
                    }),
                    { replace: true }
                  )
                }
                goToPreviousPage={() =>
                  navigate(
                    setDashboardURL({
                      filters,
                      filterValues: filters.values,
                      page: {
                        offset: pageCriteria.offset - pageCriteria.pageSize,
                        pageSize: pageCriteria.pageSize,
                      },
                      pathname: location.pathname,
                      sort: sortCriteria,
                    }),
                    { replace: true }
                  )
                }
                isClientSidePagination
                pageTotal={pipelines.length}
              />
            </>
          );
        }}
      </Query>
    </Route>
  );
};

PipelineDashboardPage.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string,
    search: PropTypes.string,
  }).isRequired,
  navigate: PropTypes.func.isRequired,
};

export default PipelineDashboardPage;

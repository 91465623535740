import {
  PIPELINE_STEP_INPUT_TYPE_DEFAULT_VALUES,
  PIPELINE_STEP_TYPES,
} from '../../../utilities/constants';

const formatCaseRules = (stepDefinitions) => (step) => {
  if (
    ![
      PIPELINE_STEP_TYPES.CASE_RULES,
      PIPELINE_STEP_TYPES.STATUS_UPDATER,
    ].includes(step.stepType)
  ) {
    return step;
  }

  const stepDefinition = stepDefinitions.find(
    (def) => def.stepType === step.stepType
  );

  const inputs = {
    ...stepDefinition.input.reduce(
      (acc, input) => ({
        ...acc,
        [input.name]: PIPELINE_STEP_INPUT_TYPE_DEFAULT_VALUES[input.type],
      }),
      {}
    ),
    ...step.inputs,
  };
  return { ...step, inputs };
};

export default formatCaseRules;

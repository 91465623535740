import { Storage } from 'aws-amplify';

const checkS3FilePath = (bucket, customPrefix, level, identityId) => (path) => {
  // TODO: Storage.get is not correct way to validate the s3 file path
  return path
    ? Storage.get(path, { bucket, customPrefix, identityId, level })
        .then(fetch)
        .then((r) => (r.status !== 200 ? 'Invalid s3 path' : null))
        .catch((err) => err)
    : null;
};

export default checkS3FilePath;

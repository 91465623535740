import Box from '@shoreag/base/Box';
import Card from '@shoreag/base/Card';
import Table from '@shoreag/base/Table';
import { capitalCase } from 'change-case';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import ItemSlider from '../../ItemSlider';
import TableWrapper from '../../TableWrapper';
import { PIPELINE_INPUTS_BLACKLIST } from '../utilities/constants';
import enhanceKeyValuePairEntries from '../../../utilities/enhance-key-value-pairs';
import PipelineCard from '../../PipelineCard';

const PipelineInformation = ({ allStepDefinitions, inputs, steps }) => {
  const [currentStep, setCurrentStep] = useState(null);

  const dataPipeline =
    steps?.length > 0
      ? [
          {
            displayName: 'Start',
            inputs: inputs.reduce(
              (acc, input) => ({
                ...acc,
                [input.name]: input.type,
              }),
              {}
            ),
            name: 'start',
          },
          ...steps,
        ]
      : [];

  const selectedStep = currentStep || dataPipeline[0];

  return (
    <>
      <ItemSlider>
        {dataPipeline.map((step, index) => {
          return (
            <PipelineCard
              isActive={selectedStep?.name === step.name}
              onClick={() => setCurrentStep(dataPipeline[index])}
              showJoiner={index < dataPipeline.length - 1}
              step={step}
            />
          );
        })}
      </ItemSlider>
      {selectedStep && (
        <Card>
          <h2>{capitalCase(selectedStep.name)}</h2>
          <Box as="h3" mt={5}>
            Input Definitions
          </Box>
          <TableWrapper>
            <Table
              header={['Input Key', 'Input Resolution']}
              rows={Object.entries(
                typeof selectedStep.inputs === 'object'
                  ? selectedStep.inputs
                  : JSON.parse(selectedStep.inputs)
              )
                .filter(([key]) => !PIPELINE_INPUTS_BLACKLIST.includes(key))
                .sort((a, b) => (a.name > b.name ? 1 : -1))
                .map(enhanceKeyValuePairEntries)}
            />
          </TableWrapper>
          {selectedStep.stepType && (
            <>
              <Box as="h3" mt={5}>
                Output Definitions
              </Box>
              <TableWrapper>
                <Table
                  header={['Output Key', 'Output Type']}
                  rows={allStepDefinitions
                    .find((def) => def.stepType === selectedStep.stepType)
                    .output.sort(([a], [b]) => (a > b ? 1 : -1))
                    .map((output) => [
                      `$$${selectedStep.name}.outputs.${output.name}`,
                      output.type,
                    ])}
                />
              </TableWrapper>
            </>
          )}
        </Card>
      )}
    </>
  );
};

PipelineInformation.propTypes = {
  allStepDefinitions: PropTypes.arrayOf({}).isRequired,
  inputs: PropTypes.arrayOf({}).isRequired,
  steps: PropTypes.arrayOf({}).isRequired,
};

PipelineInformation.defaultProps = {};

export default PipelineInformation;

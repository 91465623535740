import Input from '@shoreag/base/Input';
import PropTypes from 'prop-types';
import React from 'react';
import noop from 'lodash/noop';
import { Field } from 'react-final-form';
import {
  checkFileExtension,
  composeValidations,
  required,
} from '@shoreag/validations';
import Icon from '../../Icon';
import checkS3FilePath from '../utilities/check-s3-file-path';
import config from '../../../config.json';

const PipelineS3FileComponent = ({ isRequired, label, name }) => {
  return (
    <Field
      component={Input}
      label={label}
      name={name}
      validate={composeValidations(
        isRequired ? required : noop,
        checkFileExtension(['ipynb']),
        checkS3FilePath(
          config.buckets.runNotebook,
          { public: '' },
          'public',
          null
        )
      )}
      validationIcon={(error) => (
        <Icon height="1.2rem" svg={error ? 'fail' : 'check'} width="1.2rem" />
      )}
    />
  );
};

PipelineS3FileComponent.propTypes = {
  isRequired: PropTypes.bool,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
};

PipelineS3FileComponent.defaultProps = {
  isRequired: true,
};

export default PipelineS3FileComponent;

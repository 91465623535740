import PropTypes from 'prop-types';
import React from 'react';
import Box from '@shoreag/base/Box';
import { FieldArray } from 'react-final-form-arrays';
import DictionaryFormSection from '../PipelineValueMapperComponent/RuleBuilder/RuleVariablesSection/DictionaryFormSection';
import AddButton from '../../AddButton';

const PipelineDictionaryComponent = ({ autocompleteItems, label, name }) => {
  return (
    <>
      <Box
        alignItems="center"
        display="flex"
        justifyContent="space-between"
        mb="md"
      >
        <Box color="textSubtle">{label}</Box>
        <Box as="p" color="textSubtle" fontSize="xs">
          Add more key value pairs by click on + icon
        </Box>
      </Box>
      <FieldArray name={name}>
        {({ fields }) => (
          <Box display="flex" flexDirection="column">
            {fields.map((name, index) => (
              <Box
                key={`${name}.${index}`}
                alignItems="flex-start"
                display="flex"
                flexWrap="wrap"
                mb={3}
              >
                <DictionaryFormSection
                  autocompleteItems={autocompleteItems}
                  fields={fields}
                  index={index}
                  name={name}
                />
              </Box>
            ))}
            <Box display="flex" justifyContent="flex-end">
              <AddButton onClick={() => fields.push({})} />
            </Box>
          </Box>
        )}
      </FieldArray>
    </>
  );
};

PipelineDictionaryComponent.propTypes = {
  autocompleteItems: PropTypes.arrayOf(PropTypes.string),
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
};

PipelineDictionaryComponent.defaultProps = {
  autocompleteItems: null,
};

export default PipelineDictionaryComponent;

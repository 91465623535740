import Box from '@shoreag/base/Box';
import Card from '@shoreag/base/Card';
import Dropdown from '@shoreag/base/Dropdown';
import HorizontalBarChart from '@shoreag/charts/HorizontalBarChart';
import PropTypes from 'prop-types';
import React from 'react';
import Spinner from '@shoreag/base/Spinner';
import Table from '@shoreag/base/Table';
import formatDuration from '@shoreag/helpers/format-duration';
import noop from 'lodash/noop';
import { Field, Form } from 'react-final-form';
import { Query } from 'react-apollo';
import { capitalCase, camelCase } from 'change-case';
import { useTheme } from 'styled-components';
import { find, get } from 'lodash';
import { Location } from '@reach/router';
import { Element } from 'react-scroll';
import ErrorLogTable from '../ErrorLogTable';
import FlagHeading from '../FlagHeading';
import KeyValuePairs from '../KeyValuePairs';
import NotFoundPage from '../../routes/default/404';
import Route from '../Route';
import TableWrapper from '../TableWrapper';
import config from '../../config.json';
import enhanceKeyValuePairEntries from '../../utilities/enhance-key-value-pairs';
import filterKeyValuePairEntries from '../../utilities/filter-key-value-pair-entries';
import getExecutionResultPillDetails from '../../utilities/get-execution-result-pill-details';
import pipelineQuery from '../../graphql/queries/pipeline.gql';
import { PIPELINE_STEP_TYPES, SPECIAL_KEYS } from '../../utilities/constants';
import scrollToSection from '../../utilities/scroll-to';
import GroupAssociationView from '../GroupAssociationView';

const PipelineExecutionPage = ({ executionNumber, navigate, pipelineId }) => {
  const theme = useTheme();
  const arrangeBooleanInputs = (inputs) =>
    inputs
      .map((input, index) =>
        !['true', 'false'].includes(input[1].toLowerCase())
          ? {
              data: input,
              index: inputs.length + index,
            }
          : {
              data: input,
              index,
            }
      )
      .sort((a, b) =>
        a.index === -1 && b.index === -1 ? 0 : a.index - b.index
      )
      .map((d) => d.data);
  const getMaxXValue = (d) =>
    Object.values(d).reduce(
      (total, value) =>
        !Number.isNaN(Number(value)) ? total + Number(value) : total,
      0
    );
  const isNotErrorOrPass = (key) => {
    const isError = /^error_count/.test(key);
    const isPass = /^pass_count/.test(key);
    return !isError && !isPass;
  };
  const reorderList = {
    'Master Policy': {
      moveToIndex: 0,
    },
    'Total Passed': {
      moveToIndex: null,
    },
  };
  const outputOrderList = {
    failed: {
      moveToIndex: 1,
    },
    success: {
      moveToIndex: 0,
    },
    total_records: {
      moveToIndex: 2,
    },
  };
  const reOrderList = (list, orderReferenceObj, finderKeyIndex) => {
    const orderedList = [];
    const unOrderedList = [];
    const appendLast = [];
    list.forEach((item) => {
      const isKeyInOrderReferenceObj = orderReferenceObj[item[finderKeyIndex]];
      if (item[finderKeyIndex] && isKeyInOrderReferenceObj) {
        const { moveToIndex } = isKeyInOrderReferenceObj;
        if (moveToIndex !== null) {
          orderedList.splice(moveToIndex, 0, [moveToIndex, item]);
        } else {
          appendLast.push(item);
        }
      } else {
        unOrderedList.push(item);
      }
    });
    let resultArrangedList = [...unOrderedList, ...appendLast];
    orderedList.forEach((item) => {
      const newList = [...resultArrangedList];
      newList.splice(item[0], 0, item[1]);
      resultArrangedList = [...newList];
    });
    return resultArrangedList;
  };
  const setCaseRulesBarGraph = (resultOutputs) => {
    const accData = resultOutputs.reduce((acc, [key, rawValue]) => {
      const isError = /^error_count/.test(key);
      const isPass = /^pass_count/.test(key);
      if (!isError && !isPass) return acc;
      const value = Number(rawValue);

      const rootType = capitalCase(key.replace(/^(error|pass)_count_/, ''));

      const ruleIndex = acc.findIndex((rule) => rule.type === rootType);

      if (ruleIndex >= 0) {
        acc[ruleIndex][isError ? 'error' : 'pass'] = value;
      } else {
        acc.push({
          error: isError ? value : 0,
          pass: isPass ? value : 0,
          type: rootType,
        });
      }

      return acc;
    }, []);
    const reorderedAccData = reOrderList(accData, reorderList, 'type');
    const maxXValue = Math.max(...accData.map((d) => getMaxXValue(d)));
    const updatedAccData = reorderedAccData.map((d) => ({
      ...d,
      'not processed': maxXValue - (d.error + d.pass),
    }));
    return (
      <Box
        sx={{
          height: `calc(1.75rem * ${accData.length})`,
          mt: 5,
          position: 'relative',
        }}
      >
        <HorizontalBarChart
          colors={[
            theme.colors.success,
            theme.colors.error,
            theme.colors.gray4,
          ]}
          data={updatedAccData}
          margin={{
            bottom: 40,
            left: 220,
            right: 40,
            top: 20,
          }}
          stackKeys={['pass', 'error', 'not processed']}
          xAccessor={getMaxXValue}
          yAccessor={(d) => d.type}
          yAxisProps={{
            anchorMargin: 0,
            anchorPosition: 'end',
            rotateAnchorDegree: 0,
            rotateLabelDegree: 0,
          }}
        />
      </Box>
    );
  };
  const setResultOutputs = (result) => {
    const outputsList =
      result.stepName === PIPELINE_STEP_TYPES.CASE_RULES
        ? result.outputs.filter((item) => isNotErrorOrPass(item[0]))
        : result.outputs;
    const newResultOutputs = outputsList.sort((a, b) => {
      const isARuleOutput = a[0].includes(SPECIAL_KEYS.RULEOUTPUTS);
      const isBRuleOutput = b[0].includes(SPECIAL_KEYS.RULEOUTPUTS);
      if (isARuleOutput && isBRuleOutput) {
        return a[0].localeCompare(b[0]);
      }
      return isARuleOutput ? 1 : -1;
    });
    const resultOutputs = reOrderList(newResultOutputs, outputOrderList, 0);
    return (
      <Table
        header={['Output Key', 'Output Value']}
        rows={resultOutputs
          .filter(filterKeyValuePairEntries)
          .map(enhanceKeyValuePairEntries)}
      />
    );
  };
  return (
    <Route
      header={{
        icon: 'documents',
        title: 'Details',
        type: 'execution',
      }}
      isPrivate
    >
      <Query
        pollInterval={5000}
        query={pipelineQuery}
        variables={{
          id: pipelineId,
          searchBody: JSON.stringify({
            executionNumber,
            getExecutions: true,
            getPipeline: true,
          }),
        }}
      >
        {({ data, loading }) => {
          if (loading) return <Spinner />;
          if (!data) return <NotFoundPage />;

          const execution = find(data.pipeline.executions, { executionNumber });

          if (!execution) return <NotFoundPage />;

          const { latestExecutionVersion } = execution;
          const versionDropdownList = Array.from(
            { length: latestExecutionVersion },
            (_, index) => latestExecutionVersion - index
          ).map((versionNumber) => ({
            label: `Execution ${versionNumber}`,
            value: `${versionNumber}`,
          }));

          const executionResults = execution.results.map((result) => ({
            ...result,
            inputs: result.inputs
              ? Object.entries(JSON.parse(result.inputs))
              : [],
            outputs: result.outputs
              ? Object.entries(JSON.parse(result.outputs))
              : [],
          }));
          const executionInputs = JSON.parse(execution.inputs);
          const datasetId = get(executionInputs, 'dataset.id', null);

          return (
            <Location>
              {({ location: { hash } }) => {
                if (hash) {
                  setTimeout(() => scrollToSection(hash.slice(1)), 750);
                }
                return (
                  <>
                    <Box alignItems="center" display={{ md: 'flex' }}>
                      <Form
                        initialValues={{ executionNumber }}
                        onSubmit={noop}
                        render={() => (
                          <Box
                            maxWidth={{ md: '175px' }}
                            mb={{ md: '0', none: 'md' }}
                            ml={{ md: 'md' }}
                            width="100%"
                          >
                            <Field
                              component={Dropdown}
                              name="executionNumber"
                              onChange={(value) =>
                                navigate(
                                  `/${config.nomenclature.pipelines}/${pipelineId}/execution/${value}`
                                )
                              }
                              options={versionDropdownList}
                            />
                          </Box>
                        )}
                      />
                    </Box>
                    <Card>
                      <Box as="h3" mb={5} mt="-0.75rem">
                        Inputs
                      </Box>
                      <KeyValuePairs
                        pairs={Object.entries(executionInputs)
                          .filter(filterKeyValuePairEntries)
                          .sort(([aKey], [bKey]) => (aKey > bKey ? 1 : -1))
                          .map(enhanceKeyValuePairEntries)}
                      />
                    </Card>
                    {!!executionResults.length && (
                      <Card mt="md">
                        <Box as="h2" mb="-0.5rem" mt="-0.75rem">
                          {config.nomenclature.Step} Results
                        </Box>
                        {executionResults.map((result, i) => {
                          const {
                            bgColor,
                            status,
                            textColor,
                          } = getExecutionResultPillDetails(
                            execution.results[i]
                          );

                          const updatedInputs =
                            result.stepName === PIPELINE_STEP_TYPES.CASE_RULES
                              ? arrangeBooleanInputs(result.inputs)
                              : result.inputs;

                          return (
                            <Element
                              key={result.stepName}
                              name={camelCase(result.stepName)}
                            >
                              <FlagHeading color={bgColor}>
                                {capitalCase(result.stepName)}
                                <Box
                                  bg={bgColor}
                                  borderRadius={0}
                                  color={textColor}
                                  ml={3}
                                  px={2}
                                  py="2px"
                                >
                                  {status}
                                </Box>
                                {!!result.duration && (
                                  <Box
                                    color="textSubtle"
                                    display="inline-block"
                                    fontSize={2}
                                    ml={3}
                                  >
                                    after{' '}
                                    {formatDuration(result.duration * 1000)}
                                  </Box>
                                )}
                              </FlagHeading>
                              {!!updatedInputs.length && (
                                <>
                                  <Box as="h3" mt={5}>
                                    Inputs
                                  </Box>
                                  <TableWrapper>
                                    <Table
                                      header={['Input Key', 'Input Value']}
                                      rows={updatedInputs
                                        .filter(filterKeyValuePairEntries)
                                        .map(enhanceKeyValuePairEntries)}
                                    />
                                  </TableWrapper>
                                </>
                              )}
                              {config?.modules?.groupCondition &&
                                result.stepName ===
                                  PIPELINE_STEP_TYPES.VALUE_MAPPER &&
                                !!datasetId && (
                                  <GroupAssociationView datasetId={datasetId} />
                                )}
                              {!!result.outputs.length && (
                                <>
                                  <Box as="h3" mt={5}>
                                    Outputs
                                  </Box>
                                  <TableWrapper>
                                    {setResultOutputs(result)}
                                  </TableWrapper>
                                  {result.stepName ===
                                    PIPELINE_STEP_TYPES.CASE_RULES &&
                                    setCaseRulesBarGraph(result.outputs)}
                                </>
                              )}
                              <ErrorLogTable
                                headingProps={{ as: 'h3', fontSize: 'lg' }}
                                id={`${execution.id}:${result.stepName}`}
                              />
                            </Element>
                          );
                        })}
                      </Card>
                    )}
                  </>
                );
              }}
            </Location>
          );
        }}
      </Query>
    </Route>
  );
};

PipelineExecutionPage.propTypes = {
  executionNumber: PropTypes.string,
  navigate: PropTypes.func.isRequired,
  pipelineId: PropTypes.string,
};

PipelineExecutionPage.defaultProps = {
  executionNumber: null,
  pipelineId: null,
};

export default PipelineExecutionPage;

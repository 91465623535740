import Box from '@shoreag/base/Box';
import Card from '@shoreag/base/Card';
import { capitalCase } from 'change-case';
import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { ThemeContext } from 'styled-components';
import Icon from '../Icon';
import { PIPELINE_STEP_NAME_ICON_MAP } from '../PipelineDetailsPage/utilities/constants';

const PipelineCard = ({ isActive, onClick, step, sx, showJoiner }) => {
  const theme = useContext(ThemeContext);
  return (
    <Box
      sx={{
        display: 'flex',
        flex: 1,
        flexDirection: 'row',
        minHeight: '145px',
        minWidth: '145px',
        position: 'relative',
        px: 4,
      }}
    >
      <Card
        onClick={onClick}
        sx={{
          alignItems: 'center',
          cursor: 'pointer',
          display: 'flex',
          flex: 'auto',
          flexDirection: 'column',
          justifyContent: 'center',
          minHeight: '7.5rem',
          mt: 0,
          px: 2,
          py: 4,
          textAlign: 'center',
          ...(isActive
            ? {
                boxShadow: `0 0 0 2px ${theme.colors.accent} inset`,
                cursor: 'default',
              }
            : {}),
          ...sx,
        }}
      >
        <Box
          sx={{
            alignItems: 'center',
            bg: 'accent',
            borderRadius: '50%',
            display: 'flex',
            height: '2.5rem',
            justifyContent: 'center',
            width: '2.5rem',
          }}
        >
          <Icon
            color="white"
            mt={1}
            svg={PIPELINE_STEP_NAME_ICON_MAP[step.name] || 'repeat'}
            width="1rem"
          />
        </Box>
        <Box
          sx={{
            fontWeight: 'bold',
            mt: '4',
            wordBreak: 'break-word',
          }}
        >
          {capitalCase(step.displayName)}
        </Box>
      </Card>
      {showJoiner && (
        <Box
          color="accent"
          sx={{
            fontSize: '1rem',
            left: '100%',
            position: 'absolute',
            top: '50%',
            transform: 'translate(-50%,-50%)',
          }}
        >
          →
        </Box>
      )}
    </Box>
  );
};

PipelineCard.propTypes = {
  isActive: PropTypes.bool,
  onClick: PropTypes.func,
  showJoiner: PropTypes.bool,
  step: PropTypes.shape({
    displayName: PropTypes.string,
    name: PropTypes.string,
  }).isRequired,
  sx: PropTypes.shape({}),
};

PipelineCard.defaultProps = {
  isActive: false,
  onClick: () => null,
  showJoiner: true,
  sx: {},
};

export default PipelineCard;

import { PIPELINE_AUTOSAVE_STORAGE_KEY } from './constants';

const deletePipelineStorageData = (pipelineId) => {
  const pipelineAutoSavedData = JSON.parse(
    localStorage.getItem(PIPELINE_AUTOSAVE_STORAGE_KEY) || '{}'
  );
  delete pipelineAutoSavedData[pipelineId];
  localStorage.setItem(
    PIPELINE_AUTOSAVE_STORAGE_KEY,
    JSON.stringify(pipelineAutoSavedData)
  );
};

export default deletePipelineStorageData;

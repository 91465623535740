import PropTypes from 'prop-types';
import React from 'react';
import { AuthContext } from '@shoreag/auth';
import getPipelineTypesFromCognitoGroups from '../../utilities/get-pipeline-types-from-cognito-groups';

const PipelineTypeLabel = ({ pipelineType }) => (
  <AuthContext.Consumer>
    {({ user }) =>
      getPipelineTypesFromCognitoGroups(user.cognitoGroups).find(
        (type) => type.value === pipelineType
      )?.label || '-'
    }
  </AuthContext.Consumer>
);

PipelineTypeLabel.propTypes = {
  pipelineType: PropTypes.string.isRequired,
};

export default PipelineTypeLabel;

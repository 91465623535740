import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Box from '@shoreag/base/Box';
import areJsonEqual from '../../../utilities/are-json-equal';
import useSaveUpdatePipeline from '../lib/useSaveUpdatePipeline';
import setDataInLocalStorage from '../../../utilities/set-data-in-local-storage';
import getPipelineStorageData from '../utilities/get-pipeline-local-storage-data';
import deletePipelineStorageData from '../utilities/delete-pipeline-local-storage-data';
import {
  PIPELINE_AUTOSAVE_INTERVAL,
  PIPELINE_AUTOSAVE_STORAGE_KEY,
} from '../utilities/constants';

let autoSaveTimer = null;

const AutoSaveIndicator = ({ pipelineId }) => {
  const { isSaving, saveUpdatePipeline } = useSaveUpdatePipeline();

  const triggerAutoSave = async () => {
    const { isDataExists, pipelineStorageData } = getPipelineStorageData(
      pipelineId
    );
    if (isDataExists) {
      const { data, values: formValues, formChanged } = pipelineStorageData;
      if (formChanged) {
        clearInterval(autoSaveTimer);
        const { newPipelineId, lastUpdated } = await saveUpdatePipeline({
          data,
          pipelineId,
          values: formValues,
        });
        if (newPipelineId && lastUpdated) {
          const {
            isDataExists: newDataExists,
            pipelineStorageData: newPipelineStorageData,
          } = getPipelineStorageData(pipelineId);
          if (newDataExists) {
            const { values: localValues } = newPipelineStorageData;
            const onlyIfSomethingChanged = !(
              (formValues || localValues) &&
              areJsonEqual(formValues, localValues, 'lastUpdated')
            );
            setDataInLocalStorage({
              storageKey: PIPELINE_AUTOSAVE_STORAGE_KEY,
              targetKey: pipelineId,
              value: JSON.stringify({
                ...newPipelineStorageData,
                formChanged: onlyIfSomethingChanged,
              }),
            });
          }
          assignAutoSaveTrigger();
        }
      }
    }
  };

  const assignAutoSaveTrigger = () => {
    autoSaveTimer = setInterval(triggerAutoSave, PIPELINE_AUTOSAVE_INTERVAL);
  };

  useEffect(() => {
    deletePipelineStorageData(pipelineId);
    assignAutoSaveTrigger();
  }, []);

  return (
    <Box as="p" sx={{ color: 'accent' }}>
      Auto Save :{' '}
      <Box as="strong" sx={{ color: 'success' }}>
        ON
      </Box>
      {isSaving && (
        <Box
          as="small"
          sx={{
            '@keyframes fade': {
              '0%': {
                opacity: '0.3',
              },
              '50%': {
                opacity: '1',
              },
              '100%': {
                opacity: '0.3',
              },
            },
            animation: 'fade 2s ease-in-out infinite',
            color: 'primary',
            fontSize: '11px',
            ml: 1,
          }}
        >
          Saving...
        </Box>
      )}
    </Box>
  );
};

AutoSaveIndicator.propTypes = {
  pipelineId: PropTypes.string.isRequired,
};

export default AutoSaveIndicator;

import Box from '@shoreag/base/Box';
import Input from '@shoreag/base/Input';
import React from 'react';
import { Field } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import { required } from '@shoreag/validations';
import AddButton from '../AddButton';
import RemoveButton from '../RemoveButton';

const TagsFormSection = (props) => (
  <Box {...props}>
    <FieldArray name="tags">
      {({ fields }) => (
        <>
          {fields.map((name, index) => (
            <Box key={name} alignItems="center" display="flex" mb={5}>
              <Field
                component={Input}
                data-cy={`tagName${index}`}
                name={`${name}.name`}
                placeholder="Name"
                validate={required}
                wrapperSx={{ mr: 5 }}
              />
              <Field
                component={Input}
                data-cy={`tagValue${index}`}
                name={`${name}.value`}
                placeholder="Value"
                validate={required}
                wrapperSx={{ mt: 0 }}
              />
              <RemoveButton onClick={() => fields.remove(index)}>
                Remove
              </RemoveButton>
            </Box>
          ))}
          <Box display="flex" justifyContent="flex-end">
            <AddButton data-cy="addTag" onClick={() => fields.push({})} />
          </Box>
        </>
      )}
    </FieldArray>
  </Box>
);

export default TagsFormSection;

import Box from '@shoreag/base/Box';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { get } from 'lodash';
import parseUuid from '@shoreag/helpers/parse-uuid';
import { Field } from 'react-final-form';
import Card from '@shoreag/base/Card';
import FormError from '../FormError';
import MappingValuesCard from '../MappingValuesCard';
import ColumnDictionaryPreview from '../ColumnDictionaryPreview';
import {
  END_OF_NAME_REGEX,
  LOCAL_STORAGE_ACTIONS,
} from '../../utilities/constants';
import { COLUMN_MAPPING_TYPES } from '../PipelineEditPage/utilities/constants';
import MappingConditionsFormSection from '../MappingConditionsFormSection';

const MappingHeaderSx = {
  color: 'textSubtle',
  fontSize: 0,
  letterSpacing: 2,
  marginBottom: 4,
  textTransform: 'uppercase',
};

const fieldSortFunction = (a, b) =>
  a.fieldName.toLowerCase() > b.fieldName.toLowerCase() ? 1 : -1;

const fuzzyUuidMatch = (id1, id2) =>
  parseUuid(id1).id === parseUuid(id2).id &&
  Number(parseUuid(id1).version) === Number(parseUuid(id2).version);

const recursivelyMapDatums = (datums, schemaId, pathPrefix = '') =>
  datums.reduce(
    (fieldNames, datum) =>
      fuzzyUuidMatch(datum.schemaVersionId, schemaId)
        ? fieldNames.concat(
            datum.definitionType === 'object'
              ? recursivelyMapDatums(
                  datums,
                  datum.nestedSchemaWithVersion.id,
                  `${pathPrefix}${datum.fieldName}${
                    datum.multiple ? '[]' : ''
                  }.`
                )
              : [
                  {
                    definitionType: datum.definitionType,
                    fieldName: `${pathPrefix}${datum.fieldName}`,
                    id: datum.id,
                  },
                ]
          )
        : fieldNames,
    []
  );

const ColumnMappingFormSection = ({
  data,
  commonMapping,
  destinationSchema,
  fields,
  formContext,
  label,
  mappingRequired,
  mappingType,
  name,
  schemaStatus,
  recursive,
  sourceSchema,
}) => {
  const isGroupWiseMapping = !!mappingType;
  const columnDictionaryLocation = isGroupWiseMapping
    ? `${name}.columnMapping`
    : name;

  const [isSourceSchemaChanged, setIsSourceSchemaChanged] = useState(false);
  const [isDestinationSchemaChanged, setIsDestinationSchemaChanged] = useState(
    false
  );
  const [leftSelection, setLeftSelection] = useState(null);
  const [rightSelection, setRightSelection] = useState(null);
  const [selectedNestedData, setSelectedNestedData] = useState({
    left: null,
    right: null,
  });

  const changeColumnDictionary = ({ action, value: updatedMapping }) => {
    if (isGroupWiseMapping) {
      formContext.form.change(columnDictionaryLocation, [...updatedMapping]);
    } else {
      formContext.form.change(columnDictionaryLocation, updatedMapping);
    }
    if (action === LOCAL_STORAGE_ACTIONS.REMOVE) {
      formContext.form.change('columnDictionaryChanged', false);
    }
  };

  useEffect(() => {
    const allSourceColumns = [...data.left.map((r) => r.fieldName)];
    const allDestinationColumns = [...data.right.map((r) => r.fieldName)];
    const columnMappingLocation = isGroupWiseMapping
      ? name.replace(END_OF_NAME_REGEX, '.columnDictionary')
      : name;
    formContext.form.change(
      `${columnMappingLocation}AllDestinationColumns`,
      allDestinationColumns
    );

    if (isSourceSchemaChanged || isDestinationSchemaChanged) {
      let updatedMapping = [...columnDictionary];
      if (isSourceSchemaChanged) {
        updatedMapping = columnDictionary.filter((column) =>
          allSourceColumns.includes(column[0])
        );
      }
      if (isDestinationSchemaChanged) {
        updatedMapping = columnDictionary.filter((column) =>
          allDestinationColumns.includes(column[1])
        );
      }
      changeColumnDictionary(updatedMapping);
    }
  }, [data]);

  useEffect(() => {
    if (
      schemaStatus.isSourceSchemaChanged ||
      schemaStatus.isDestinationSchemaChanged
    ) {
      setIsSourceSchemaChanged(schemaStatus.isSourceSchemaChanged);
      setIsDestinationSchemaChanged(schemaStatus.isDestinationSchemaChanged);
    }
  }, [schemaStatus]);

  const columnDictionary = get(
    formContext.values,
    columnDictionaryLocation,
    []
  );
  const leftValues = columnDictionary.map((v) => v[0]);
  const rightValues = columnDictionary.map((v) => v[1]);

  const columnMappingValidation = (value) => {
    if (
      mappingRequired &&
      data.right.some(
        ({ fieldName }) => !(value || []).map((v) => v[1]).includes(fieldName)
      )
    ) {
      return 'All destination columns must be mapped';
    }
  };

  const setData = (dataList, schemaId) =>
    recursive ? recursivelyMapDatums(dataList, schemaId) : dataList;

  return (
    <Card sx={{ bg: 'gray1', boxShadow: 'none', p: 4 }}>
      {label}
      {isGroupWiseMapping &&
        !commonMapping &&
        mappingType !== COLUMN_MAPPING_TYPES.FLAT && (
          <MappingConditionsFormSection
            formContext={formContext}
            mappingType={mappingType}
            name={name}
            schemaList={
              mappingType === COLUMN_MAPPING_TYPES.DISTRIBUTION
                ? setData(data.right, destinationSchema)
                : setData(data.left, sourceSchema)
            }
          />
        )}

      <Box display="flex">
        <Box mr="md" width="50%">
          <Box sx={MappingHeaderSx}>Available Source Columns</Box>
          <MappingValuesCard
            data={setData(data.left, sourceSchema)
              .filter((def) => recursive || !leftValues.includes(def.fieldName))
              .sort(fieldSortFunction)}
            onDatumSelect={({ fieldName }) => {
              if (rightSelection) {
                if (isGroupWiseMapping) {
                  changeColumnDictionary({
                    action: LOCAL_STORAGE_ACTIONS.ADD,
                    value: [...columnDictionary, [fieldName, rightSelection]],
                  });
                } else {
                  fields.push([fieldName, rightSelection]);
                }
                setRightSelection(null);
              } else {
                setLeftSelection(fieldName);
              }
            }}
            onSchemaSelect={(schema) => {
              setSelectedNestedData((prevState) => ({
                ...prevState,
                left: schema,
              }));
            }}
            searchName="leftSearch"
            selectedData={selectedNestedData?.left}
          />
        </Box>
        <Box width="50%">
          <Box sx={MappingHeaderSx}>Available Destination Columns</Box>
          <MappingValuesCard
            data={setData(data.right, destinationSchema)
              .filter((def) => !rightValues.includes(def.fieldName))
              .sort(fieldSortFunction)}
            onDatumSelect={({ fieldName }) => {
              if (leftSelection) {
                if (isGroupWiseMapping) {
                  changeColumnDictionary({
                    action: LOCAL_STORAGE_ACTIONS.ADD,
                    value: [...columnDictionary, [leftSelection, fieldName]],
                  });
                } else {
                  fields.push([leftSelection, fieldName]);
                }
                setLeftSelection(null);
              } else {
                setRightSelection(fieldName);
              }
            }}
            onSchemaSelect={(schema) => {
              setSelectedNestedData((prevState) => ({
                ...prevState,
                right: schema,
              }));
            }}
            searchName="rightSearch"
            selectedData={selectedNestedData?.right}
          />
          <Field
            name={columnDictionaryLocation}
            validate={columnMappingValidation}
          >
            {({ meta }) => <FormError mt="sm">{meta.error}</FormError>}
          </Field>
        </Box>
      </Box>
      {(leftSelection || rightSelection || !!columnDictionary.length) && (
        <Card
          as="section"
          sx={{
            bg: 'gray3',
            boxShadow: 'none',
            pb: 4,
            pt: 0,
            px: 4,
          }}
        >
          <ColumnDictionaryPreview
            columnDictionary={columnDictionary}
            leftSelection={leftSelection}
            onRemove={(i) => {
              if (i === -1) {
                setLeftSelection(null);
                setRightSelection(null);
              } else if (isGroupWiseMapping) {
                columnDictionary.splice(i, 1);
                changeColumnDictionary({
                  action: LOCAL_STORAGE_ACTIONS.REMOVE,
                  value: columnDictionary,
                });
              } else {
                fields.remove(i);
              }
            }}
            rightSelection={rightSelection}
          />
        </Card>
      )}
    </Card>
  );
};

ColumnMappingFormSection.propTypes = {
  commonMapping: PropTypes.bool,
  data: PropTypes.shape({
    left: PropTypes.arrayOf(PropTypes.shape({})),
    right: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
  destinationSchema: PropTypes.string.isRequired,
  fields: PropTypes.shape({ push: PropTypes.func, remove: PropTypes.func }),
  formContext: PropTypes.shape({
    form: PropTypes.shape({
      change: PropTypes.func,
    }),
    values: PropTypes.shape({}),
  }).isRequired,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  mappingRequired: PropTypes.bool.isRequired,
  mappingType: PropTypes.string,
  name: PropTypes.string.isRequired,
  recursive: PropTypes.bool.isRequired,
  schemaStatus: PropTypes.shape({
    isDestinationSchemaChanged: PropTypes.bool,
    isSourceSchemaChanged: PropTypes.bool,
  }),
  sourceSchema: PropTypes.string.isRequired,
};

ColumnMappingFormSection.defaultProps = {
  commonMapping: true,
  fields: null,
  label: null,
  mappingType: '',
  schemaStatus: {
    isDestinationSchemaChanged: false,
    isSourceSchemaChanged: false,
  },
};

export default ColumnMappingFormSection;

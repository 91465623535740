import PropTypes from 'prop-types';
import React, { useMemo, useState } from 'react';
import Box from '@shoreag/base/Box';
import Button from '@shoreag/base/Button';
import DropdownMenu from '@shoreag/base/DropdownMenu';
import formatDateTimeConcise from '@shoreag/helpers/format-date-time-concise';
import Table from '@shoreag/base/Table';
import copy from 'clipboard-copy';
import { sentenceCase } from 'change-case';
import config from '../../../config.json';
import ResponsiveEllipsis from '../../ResponsiveEllipsis';
import TagTooltip from '../../TagTooltip';
import {
  END_OF_STRING_REGEX,
  PERMISSION_ACTIONS,
  PERMISSION_RESOURCES,
} from '../../../utilities/constants';
import isPermitted from '../../../utilities/is-permitted';
import DotsIcon from '../../DotsIcon';
import renderDropdownMenuItem from '../../../utilities/render-dropdown-menu-item';
import ReactAdvanceTable from '../../ReactAdvanceTable';
import Icon from '../../Icon';
import ProgressStatusBarUi from '../../ProgressStatusBar/ui';
import DrawerSidebar from '../../DrawerSidebar';

const PipelineTable = ({ user, data, loading }) => {
  const [pipeLineData, setPipelineData] = useState([]);
  const [selectedPipeline, setSelectedPipeLine] = useState(null);

  const hasWritePermission = isPermitted({
    desiredAction: PERMISSION_ACTIONS.W,
    desiredResource: PERMISSION_RESOURCES.PIPELINES,
    ...user,
  });

  const hasExecutePermission = isPermitted({
    desiredAction: PERMISSION_ACTIONS.X,
    desiredResource: PERMISSION_RESOURCES.PIPELINES,
    ...user,
  });

  const handleFetchScheduledPipelone = (pipeline) => {
    const updatedPipeline = data.map((i) =>
      i.id === pipeline.id
        ? {
            ...i,
            subRows: [
              { ...pipeline, versionNumber: pipeline.versionNumber - 1 },
            ],
          }
        : i
    );
    setPipelineData([...updatedPipeline]);
  };

  // Dummy data
  const setScheduleTable = () => {
    const { schedules, id } = selectedPipeline;

    return (
      <Table
        header={['Version', 'Interval', 'From', 'To', '']}
        rows={schedules.map((schedule, index) => {
          const { version, from, to, interval, scheduled } = schedule;
          const dropdownItems = [
            scheduled ? ['close', 'Cancel schedule'] : null,
            ['clock', 'Rechedule'],
            ['clock', 'Execute'],
          ].filter((i) => i);
          const rescheduleAndExecute = [
            `/${config.nomenclature.pipelines}/${id.replace(
              END_OF_STRING_REGEX(':'),
              `:${version}`
            )}/schedule`,
            `/${config.nomenclature.pipelines}/${id.replace(
              END_OF_STRING_REGEX(':'),
              `:${version}`
            )}/schedule`,
          ];
          const dropdownActions = scheduled
            ? [null, ...rescheduleAndExecute]
            : rescheduleAndExecute;
          return [
            <Box
              key={`${index}-${index}`}
              sx={{
                bg: 'grays.4',
                borderRadius: 0,
                color: 'white',
                display: 'inline-block',
                fontSize: 1,
                fontWeight: 'bold',
                letterSpacing: 1,
                mr: 2,
                textAlign: 'center',
                textTransform: 'uppercase',
                width: '2rem',
              }}
            >
              v{version}
            </Box>,
            scheduled ? interval : '-',
            scheduled ? formatDateTimeConcise(from) : '-',
            scheduled ? formatDateTimeConcise(to) : '-',
            <Box sx={{ float: 'right' }}>
              <DropdownMenu
                button={<DotsIcon />}
                itemActions={dropdownActions}
                items={dropdownItems.map(renderDropdownMenuItem)}
                menuZIndex={10}
              />
            </Box>,
          ];
        })}
      />
    );
  };

  const setProgressStatusBar = (status) => {
    const { completedAt, label, value } = status;
    const progress = label ? value : 0;
    let success = 0;
    let fail = 0;
    if (completedAt) {
      success = value;
      fail = 100 - value;
    }
    return (
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <Box as="p" sx={{ color: 'primary', fontSize: 1 }}>
          {label ? sentenceCase(label) : formatDateTimeConcise(completedAt)}
        </Box>
        <ProgressStatusBarUi
          failure={fail}
          failureMessage={`${fail} failed`}
          progress={progress}
          progressMessage={progress ? `${progress} %` : ''}
          success={success}
          successMessage={`${success} passed`}
          width={200}
        />
      </Box>
    );
  };

  const columns = useMemo(
    () => [
      {
        accessorKey: 'versionNumber',
        cell: (cell) => {
          const pipeline = cell.row.original;
          const versionNumber = cell.getValue();
          const isMoreVersionAvailable = versionNumber > 1;
          const { depth, subRows } = cell.row;
          const isExpanded =
            (subRows.length > 0 && cell.row.getIsExpanded()) || depth > 0;

          return versionNumber ? (
            <Box sx={{ display: 'flex' }}>
              <Box
                sx={{
                  bg: 'grays.4',
                  borderRadius: 0,
                  color: 'white',
                  display: 'inline-block',
                  fontSize: 1,
                  fontWeight: 'bold',
                  letterSpacing: 1,
                  mr: 2,
                  textAlign: 'center',
                  textTransform: 'uppercase',
                  width: '2rem',
                }}
              >
                v{versionNumber}
              </Box>
              {depth === 0 && isMoreVersionAvailable && (
                <Icon
                  onClick={() => {
                    if (subRows.length > 0) {
                      cell.row.toggleExpanded();
                    } else {
                      handleFetchScheduledPipelone(pipeline);
                    }
                  }}
                  size="1.065rem"
                  svg="right-chevron"
                  sx={{
                    alignItems: 'center',
                    color: 'grays.3',
                    cursor: 'pointer',
                    display: 'flex',
                    justifyContent: 'center',
                    ml: 2,
                    svg: { height: '0.8rem' },
                    transform: `rotate(${isExpanded ? '90deg' : ''})`,
                  }}
                />
              )}
            </Box>
          ) : (
            '-'
          );
        },
        header: '',
        id: 'versionNumber',
      },
      {
        accessorKey: 'displayName',
        cell: (cell) => {
          const displayName = cell.getValue();
          const pipeline = cell.row.original;
          return displayName ? (
            <Button
              link={`/${config.nomenclature.pipelines}/${pipeline.id}`}
              simple
              sx={{ width: '100%' }}
              variant="buttons.link"
            >
              <ResponsiveEllipsis text={displayName} />
            </Button>
          ) : (
            '-'
          );
        },
        header: 'Name',
        id: 'name',
      },
      {
        accessorKey: 'versionNumber',
        cell: (cell) => {
          const value = cell.getValue();
          const pipeline = cell.row.original;
          return value ? (
            <Button onClick={() => setSelectedPipeLine(pipeline)} simple>
              {value}
            </Button>
          ) : (
            '-'
          );
        },
        header: 'Active versions',
        id: 'activeVersions',
      },
      {
        accessorKey: 'lastUpdated',
        cell: (cell) => {
          const value = cell.getValue();
          return value ? formatDateTimeConcise(value) : '-';
        },
        header: 'Next Execution',
        id: 'nextExecution',
      },
      {
        accessorKey: 'status',
        cell: (cell) => {
          const status = cell.getValue();
          return status ? setProgressStatusBar(status) : '-';
        },
        header: 'Status',
        id: 'status',
      },
      {
        accessorKey: 'steps',
        cell: (cell) => {
          const steps = cell.getValue();
          return steps ? (
            <TagTooltip
              data-tip-disable={!steps.length}
              id="tags"
              sx={{ textAlign: 'center' }}
              tags={steps.map((step) => step.name)}
            >
              {String(steps.length)}
            </TagTooltip>
          ) : (
            '-'
          );
        },
        header: <Box textAlign="center">{config.nomenclature.Steps}</Box>,
        id: 'steps',
      },
      {
        accessorKey: 'partner.partnerName',
        cell: (cell) => {
          const value = cell.getValue();
          return value || '-';
        },
        header: 'Partner Name',
        id: 'partnerName',
      },
      {
        accessorKey: 'autoTrigger',
        cell: (cell) => {
          const value = cell.getValue();
          return value && value === 'yes' ? (
            <Icon size="1rem" svg="check" sx={{ color: 'accent' }} />
          ) : (
            ''
          );
        },
        header: 'Auto Trigger',
        id: 'autoTrigger',
      },
      {
        accessorKey: 'actionColumn',
        cell: (cell) => {
          const pipeline = cell.row.original;
          const dropdownItems = [['view', 'View']];

          const dropdownActions = [
            `/${config.nomenclature.pipelines}/${pipeline.id}`,
          ];

          if (hasWritePermission) {
            dropdownItems.push(['edit', 'Edit']);

            dropdownActions.push(
              `/${config.nomenclature.pipelines}/${pipeline.id}/edit`
            );
          }

          if (pipeline.isPublished && hasExecutePermission) {
            dropdownItems.push(['clock', 'Reschedule']);

            dropdownActions.push(
              `/${config.nomenclature.pipelines}/${pipeline.id}/schedule`
            );
          }

          dropdownItems.push(['copy', 'Copy ID']);
          dropdownActions.push(() => copy(pipeline.id));

          if (pipeline.isPublished && hasExecutePermission) {
            dropdownItems.push(['clock', 'Execute']);

            dropdownActions.push(
              `/${config.nomenclature.pipelines}/${pipeline.id}/schedule`
            );
          }
          return (
            <Box sx={{ float: 'right' }}>
              <DropdownMenu
                button={<DotsIcon />}
                itemActions={dropdownActions}
                items={dropdownItems.map(renderDropdownMenuItem)}
              />
            </Box>
          );
        },
        disableSortBy: true,
        header: '',
        id: 'actions',
      },
    ],
    [data]
  );
  return (
    <>
      <ReactAdvanceTable
        columns={columns}
        data={pipeLineData.length > 0 ? pipeLineData : data}
        isLoading={loading}
        tableSx={{
          'tr.isExpanded': {
            bg: 'grays.1',
            borderColor: 'grays.3',
            borderLeftStyle: 'solid',
            borderLeftWidth: '2px',
          },
        }}
      />
      {!!selectedPipeline && (
        <DrawerSidebar
          contentSx={{
            maxWidth: '50%',
          }}
          isOpen={!!selectedPipeline}
          onClose={() => setSelectedPipeLine(null)}
          subtitle={selectedPipeline.displayName}
          title="Schedules"
        >
          {setScheduleTable()}
        </DrawerSidebar>
      )}
    </>
  );
};

PipelineTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  loading: PropTypes.bool,
  user: PropTypes.shape({}).isRequired,
};

PipelineTable.defaultProps = {
  loading: false,
};

export default PipelineTable;

import Box from '@shoreag/base/Box';
import Input from '@shoreag/base/Input';
import React from 'react';
import { Field } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import Dropdown from '@shoreag/base/Dropdown';
import PropTypes from 'prop-types';
import { required } from '@shoreag/validations';
import { get } from 'lodash';
import {
  COLUMN_MAPPING_TYPES,
  RULE_OPERAND_OPTIONS,
} from '../PipelineEditPage/utilities/constants';
import Icon from '../Icon';
import { END_OF_NAME_REGEX } from '../../utilities/constants';

const MappingConditionsFormSection = ({
  formContext,
  mappingType,
  name,
  schemaList,
}) => {
  const isDistribution = mappingType === COLUMN_MAPPING_TYPES.DISTRIBUTION;
  const initialValues = {
    datumType: '',
    [isDistribution ? 'destinationColumn' : 'sourceColumn']: '',
    [isDistribution ? 'value' : 'variable']: '',
  };

  if (!isDistribution) {
    initialValues.operand = '';
  }

  const ruleSetLocation = name.replace(END_OF_NAME_REGEX, '.ruleSet');
  const variableDefinitions = get(
    formContext.values,
    `${ruleSetLocation}.variableDefinitions`,
    []
  );
  return (
    <FieldArray name={`${name}.conditions`}>
      {({ fields }) => {
        return (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              mb: 5,
              width: '100%',
            }}
          >
            {fields.map((name, index) => {
              return (
                <Box
                  key={name}
                  sx={{
                    display: 'flex',
                    flex: 'auto',
                    mb: 5,
                    mr: 1,
                  }}
                >
                  <Field
                    component={Dropdown}
                    label={
                      isDistribution ? `Destination Column` : `Source Column`
                    }
                    name={`${name}.${
                      isDistribution ? 'destinationColumn' : 'sourceColumn'
                    }`}
                    onChange={(value) => {
                      const definitionType = schemaList.find(
                        (schema) => schema.fieldName === value
                      )?.definitionType;
                      formContext.form.change(
                        `${name}.datumType`,
                        definitionType
                      );
                    }}
                    options={schemaList.map(({ fieldName }) => ({
                      label: fieldName,
                      value: fieldName,
                    }))}
                    // placeholder="Source Columns"
                    validate={required}
                    wrapperSx={{ mt: 0 }}
                  />
                  <Field
                    component={Input}
                    disabled
                    label="Datum Type"
                    name={`${name}.datumType`}
                    // placeholder="Value"
                    wrapperSx={{ ml: 5, mt: 0, opacity: '1' }}
                  />
                  {isDistribution ? (
                    <Field
                      component={Input}
                      label="Value"
                      name={`${name}.value`}
                      // placeholder="Value"
                      wrapperSx={{ ml: 5, mt: 0 }}
                    />
                  ) : (
                    <>
                      <Field
                        component={Dropdown}
                        label="Variable"
                        name={`${name}.variable`}
                        options={variableDefinitions.map(
                          (variableDefinition) => {
                            return {
                              label: variableDefinition.variableOutput,
                              value: variableDefinition.variableOutput,
                            };
                          }
                        )}
                        // validate={required}
                        wrapperSx={{ ml: 5, mt: 0 }}
                      />
                      <Field
                        component={Dropdown}
                        label="AND / OR"
                        name={`${name}.operand`}
                        onChange={() => fields.push(initialValues)}
                        options={RULE_OPERAND_OPTIONS}
                        // placeholder="AND / OR"
                        wrapperSx={{
                          flex: 'none',
                          ml: 5,
                          mt: 0,
                          width: '150px',
                        }}
                      />
                    </>
                  )}
                  <Box
                    sx={{
                      alignItems: 'center',
                      display: 'flex',
                      height: 'heights.input',
                      ml: 4,
                      mt: 5,
                    }}
                  >
                    <Icon
                      onClick={() => fields.remove(index)}
                      svg="close"
                      sx={{
                        bg: 'error',
                        borderRadius: '50%',
                        color: 'white',
                        display: 'inline-flex',
                        height: '1rem',
                        px: '.25rem',
                        width: '1rem',
                      }}
                    />
                  </Box>
                </Box>
              );
            })}
            {(fields.length === 0 || isDistribution) && (
              <Box
                onClick={() => fields.push(initialValues)}
                sx={{
                  alignItems: 'center',
                  color: 'textSubtle',
                  cursor: 'pointer',
                  display: 'flex',
                  fontsize: 1,
                  fontWeight: 'bold',
                  ml: 'auto',
                  mt: fields.length === 0 ? -7 : '',
                }}
              >
                Add Condition
                <Box
                  sx={{
                    alignItems: 'center',
                    bg: 'primary',
                    borderRadius: '50%',
                    color: 'white',
                    display: 'flex',
                    fontSize: 'sm',
                    height: '1.4rem',
                    justifyContent: 'center',
                    minWidth: '1.4rem',
                    ml: 2,
                  }}
                >
                  +
                </Box>
              </Box>
            )}
          </Box>
        );
      }}
    </FieldArray>
  );
};

MappingConditionsFormSection.propTypes = {
  formContext: PropTypes.shape({
    form: PropTypes.shape({
      change: PropTypes.func,
    }),
    values: PropTypes.shape({}),
  }).isRequired,
  mappingType: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  schemaList: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

MappingConditionsFormSection.defaultProps = {};

export default MappingConditionsFormSection;

import PropTypes from 'prop-types';
import React from 'react';
import Box from '@shoreag/base/Box';
import Spinner from '@shoreag/base/Spinner';
import stripTypename from '@shoreag/helpers/strip-typename';
import { useQuery } from 'react-apollo';
import get from 'lodash/get';
import RuleSection from './RuleSection';
import allRecursiveSchemaDatumQuery from '../../../graphql/queries/all-recursive-schema-datum.gql';
import {
  PIPELINE_STEP_TYPES,
  RULE_SECTIONS,
} from '../../../utilities/constants';

const getNestedFieldName = (datumList, schemaId, fieldName) => {
  const parentSchemaDatum = datumList.find(
    (d) => d.nestedSchemaWithVersion?.id === schemaId
  );
  if (parentSchemaDatum?.definitionType === 'object') {
    return getNestedFieldName(
      datumList,
      parentSchemaDatum.schemaVersionId,
      `${parentSchemaDatum.fieldName}.${fieldName}`
    );
  }
  return parentSchemaDatum
    ? `${parentSchemaDatum.fieldName}.${fieldName}`
    : fieldName;
};

const updateDatumFieldName = (datumList) => {
  return datumList.map((datum) =>
    datum.definitionType !== 'object'
      ? {
          ...datum,
          fieldName: getNestedFieldName(
            datumList,
            datum.schemaVersionId,
            datum.fieldName
          ),
        }
      : datum
  );
};

const datumSortFunction = (datums) =>
  datums
    .sort((a, b) =>
      a.fieldName.toLowerCase() > b.fieldName.toLowerCase() ? 1 : -1
    )
    .sort((a, b) => {
      return (
        (a.fieldName.toString().match(/\./g) || []).length -
        (b.fieldName.toString().match(/\./g) || []).length
      );
    });

const PipelineValueMapperComponent = ({
  formContext,
  name,
  sourceSchema,
  stepType,
}) => {
  const { data, loading } = useQuery(allRecursiveSchemaDatumQuery, {
    variables: { id: sourceSchema },
  });
  const datums = datumSortFunction(
    updateDatumFieldName(
      stripTypename(get(data, 'allRecursiveSchemaDatum', []))
    )
  );
  return (
    <Box>
      {loading && <Spinner py="md" />}
      {!!data && (
        <Box display="flex">
          <RuleSection
            formContext={formContext}
            hideSections={
              stepType === PIPELINE_STEP_TYPES.NORMALIZER
                ? [RULE_SECTIONS.RULE_BUILDER, RULE_SECTIONS.RULE_LIST]
                : []
            }
            name={name}
            schemaColumns={datums}
          />
        </Box>
      )}
    </Box>
  );
};

PipelineValueMapperComponent.propTypes = {
  formContext: PropTypes.shape({
    values: PropTypes.shape({
      inputs: PropTypes.arrayOf(
        PropTypes.shape({ input: PropTypes.string, type: PropTypes.string })
      ),
      steps: PropTypes.arrayOf(PropTypes.shape({ stepType: PropTypes.string })),
    }),
  }).isRequired,
  name: PropTypes.string.isRequired,
  sourceSchema: PropTypes.string.isRequired,
  stepType: PropTypes.string.isRequired,
};

export default PipelineValueMapperComponent;
